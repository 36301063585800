import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  PRIMARY_OUTLET,
  Router,
  RouterStateSnapshot,
  UrlSerializer,
  UrlTree,
} from "@angular/router";
import { from, Observable } from "rxjs";
import { filter, first, map, mergeMap, switchMap, take, tap } from "rxjs/operators";
import { AppQuery, LoginStatus } from "./app.store";
import {
  AuthenticationService,
  KeyGetter,
  ProductSettingService,
} from "./services";


// Tenant Guard - must ensure that User is Authenticated and Tenant data is set (setting, company info, etc...)
// Auth Guard - only check for auth status

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private appQuery: AppQuery,
    private urlSerializer: UrlSerializer
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.appQuery.$loginStatus.pipe(
      map((status) => {
        let returnUrl = "";
        let tenant = route.paramMap.get("tenant") || "";
        if (tenant === "login") {
          tenant = "";
        }
        if (status === LoginStatus.False) {
          if (state.url && state.url.length > 1) {
            const tree = this.urlSerializer.parse(state.url);
            const g = tree.root.children[PRIMARY_OUTLET];
            returnUrl = g && g.segments ? g.segments.join("/") : "";
            returnUrl = returnUrl.replace(tenant, "");
          }
          if (tenant) {
            tenant = "/" + tenant;
          }
          const params = !!returnUrl ? { queryParams: { returnUrl } } : {};
          this.router.navigate([tenant + "/login"], params);
          return false;
        }
        return true;
      }),
      first()
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class PublicGuard implements CanActivate {
  constructor(
    private appQuery: AppQuery,
    private authServe: AuthenticationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.appQuery.$loginStatus.pipe(
      filter((s) => s !== LoginStatus._),
      switchMap((status) => {
        return from(
          new Promise<boolean>((resolve, reject) => {
            // dont allow Authed users to access public pages, must logout first
            if (status === LoginStatus.True && state.url.includes("/public")) {
              this.authServe.logout(false).then(() => {
                // window.location.reload(); - probably not necessary for a full reload
                resolve(true);
              });
            } else {
              resolve(true);
            }
          })
        );
      })
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class TenantGuard implements CanActivate {
  constructor(
    private appQuery: AppQuery,
    private productSettingService: ProductSettingService,
    private router: Router,
    private authService: AuthenticationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.appQuery.$tenant2.pipe(
      take(1),
      mergeMap((tenant) => {
        const newTenant =
          route.root.paramMap.get("tenant") ||
          route.paramMap.get("tenant") ||
          "";

        if (!!newTenant && !KeyGetter.companies.includes(newTenant)) {
          console.log(`TenantGuard: invalid tenant: ${newTenant} - re-routing to /`);
          return from([this.router.createUrlTree(["/"])]);
        }

        if (newTenant !== tenant) {
          console.log("AuthGuard: Tenant Change: " + tenant + " -> " + newTenant);
          return this.productSettingService.tenantChanged(newTenant)
          .pipe(
            mergeMap(() => this.authService.doBigTenantRefresh()),
            mergeMap(() => this.productSettingService.$loading),
            filter((l) => !l),
            mergeMap(() => this.appQuery.$loginStatus),
            tap(loginStatus => console.log(`Setting Service: - loginStatus:${loginStatus}`)),
            map((loginStatus) => {
              console.log(`TenantGuard: loginStatus: ${loginStatus}`);
              if (state.url.includes("/public")) {
                return true;
              } else {
                if(loginStatus === LoginStatus.False) {
                  console.log(`TenantGuard: loginStatus: ${loginStatus} - re-routing to /login`);
                  return this.router.createUrlTree(["/login"]);
                }
              }
              return true;
            })
          );
        } else {
          // wait for finish loading.
          return this.productSettingService.$loading.pipe(
            filter((l) => !l),
            map(() => true)
          );
        }
      })
    );
  }
}

/**
 * Prevent Further navigation if FP APi is detected
 * Note: must implement a manual navigation strategy to handle these Canceled Navigation events elegantly
 * See: Evo-Launcher for some trickery
 */
@Injectable({
  providedIn: "root",
})
export class EvoGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private appQuery: AppQuery,
    private urlSerializer: UrlSerializer
  ) {}
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    console.log("## EvoGuard running");
    if (['evom','evom-demo'].includes(this.appQuery.tenant) && "initFPEditor" in window) {
      console.log("## EvoGuard blocked Nav");
      return from([false]);
    }
    return from([true]);
  }
}
