import { Component, ComponentRef, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, ViewChild, ViewContainerRef } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { AppQuery, LoginStatus } from "@modules/common/app.store";
import {
  codeOnly,
  JobJournalDto,
  MachineMasterDto,
  ModelMasterDto,
  newModelMaster
} from "@shared/models";
import { FieldSettings } from "@shared/models/FieldSettings";
import { convertToDate, isValidDate } from "@modules/common";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";
import { CUSTOM_LAYOUT_ID, JobService, ProductSettingService, WebLayoutService } from "../../services";
import { BaseModal } from "../base-modal";
import { ModelMasterComponent } from "../model-master/model-master.component";
import { ScanResult } from "../scan/scan.component";
import { UnlockMachineModalComponent } from "../unlock-machine-modal/unlock-machine-modal.component";

type FieldContextTypes = 'text' | 'lookup' | 'checkbox';
interface FieldContext {
  name: string,
  id: string,
  type: FieldContextTypes,
  lookup?: string,
  readonly?: boolean
};


export function createDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
      const value = (control.value as string);
      if (!value) {
          return null;
      }
      const dateValid = isValidDate(value);
      const returnValue = {
        ...(dateValid ? {} : {date: false}),
        // Our validation message generates messages based on existence of keys, and not the 'values' truthyness (as per Angular examples)
      };
      const isValid = dateValid;
      return !isValid ? returnValue : null;
  };
}


@Component({
  selector: "abi-machine-master",
  templateUrl: "machine-master.component.html",
  styleUrls: ["./machine-master.component.scss"]
})
export class MachineMasterComponent extends BaseModal<MachineMasterDto>
  implements OnInit, OnChanges {
  @ViewChild('attachmentsLabel')
  labelImport: ElementRef;
  static validationMessages = {
    modelId: {
      required: "Machine.ModelIDRequired"
    },
    dateOfPurchase: {
      required: "Machine.DateOfPurchaseRequired"
    },
    serialNumber: {
      required: "Machine.SerialRequired"
    },
    field1: {
      required: "Machine.Field1Required"
    },
    field2: {
      required: "Machine.Field2Required"
    },
    field3: {
      required: "Machine.Field3Required"
    },
    field4: {
      required: "Machine.Field4Required"
    },
    field5: {
      required: "Machine.Field5Required"
    },
    dealerBranchId: {
      required: "Machine.DealerBranchRequired"
    },
    fileUpload: {
      required: "Machine.FileUploadRequired"
    }
  };

  showButton: boolean;
  @Input() horizontal = true;
  @Input() context: "Master" | "Registration" | "Job" = "Master";
  @Input() filterModelsBy: string;
  @Output() addJournal = new EventEmitter<JobJournalDto>();
  categoryCount = 2;
  fieldSettings: FieldSettings;

  get categoryControls(): FormArray {
    return this.group.get("categories") as FormArray;
  }

  get selectedCategories(): string[] {
    return this.categoryControls.value;
  }

  static createFormGroup(fb: FormBuilder, extra: { [key: string]: any } = {}) {
    const result = fb.group({
      machineId: "",
      categories: fb.array([]),
      modelId: ["", { validators: Validators.required}],
      model: null, // for reference only - not required in Server POST
      name: { value: "", disabled: true },
      serialNumber: "",
      dateOfPurchase: ["",{ validators: createDateValidator()}],
      dealerBranchId: "",
      field1: "",
      field2: "",
      field3: "",
      field4: "",
      field5: "",
      locationId: "",
      owner: "",
      warehouseId: "",
      // TODO: convert to a FormArray for the Multi-File-Upload-Component
      attachment: [null],
      ...extra
    });
    return result;
  }

  static setFormData(
    machine: MachineMasterDto,
    group: FormGroup,
    productSettings: ProductSettingService
  ) {
    const catCount = Math.max(
      2,
      productSettings.numericValue("MachineCategoryCount")
    );
    let model = machine.model;
    if (!model) {
      model = newModelMaster();
      model.categories = Array(catCount).fill("");
    }

    // group.setControl("categories", new FormArray([]));
    // limit any existing db categories to a defined front-end value
    const categories = Array(catCount).fill("");
    if (model.categories.some(c => !!c)) {
      categories.splice(0, model.categories.length, ...model.categories.filter((v,i) => i < catCount));
    } else if (machine.categories && machine.categories.length) {
      categories.splice(0, machine.categories.length, ...machine.categories.filter((v,i) => i < catCount));
    }
    /*
    const categories =
      (model.categories.some(c => !!c)
        ? model.categories
        : machine.categories && machine.categories.length
        ? machine.categories
        : null) || Array(catCount).fill("");*/
    const cats = new FormArray(
      categories.map(
        c => new FormControl({ value: c, disabled: group.disabled })
      )
    );
    const base = {
      field1: machine.fields[0],
      field2: machine.fields[1],
      field3: machine.fields[2],
      field4: machine.fields[3],
      field5: machine.fields[4],
      modelId: machine.model
    };
    group.reset({ ...base, ...machine });
    group.setControl("categories", cats);

    if (group.disabled || (machine.model &&
      machine.model.categories &&
      machine.model.categories.some(c => !!c))
    ) {
      group.get("name").disable();
    } else {
      group.get("name").enable();
    }
  }

  static getFormData(machine: MachineMasterDto, formGroup: FormGroup) {
    const value = formGroup.getRawValue();
    machine.dateOfPurchase = convertToDate(value.dateOfPurchase);

    machine.modelId = value.modelId.code || value.modelId || "Unknown";// Support "Unknown" Model for PK/FK integrity;
    machine.name = value.name;
    // if (formGroup.get("serialNumber").dirty) {
      machine.serialNumber = value.serialNumber;
    // }
    machine.dealerBranchId = codeOnly(value.dealerBranchId);
    machine.fields[0] = value.field1;
    machine.fields[1] = value.field2;
    machine.fields[2] = value.field3;
    machine.fields[3] = value.field4;
    if (formGroup.get("locationId").dirty) {
      const locationId = codeOnly(value.locationId);
      if (!machine.locationId || locationId)
        machine.locationId = locationId;
    }
    machine.owner = codeOnly(value.owner);
    machine.warehouseId = codeOnly(value.warehouseId);

    // Special Checkbox field handling
    if (typeof value.field5 === "boolean" || typeof value.field5 === "number")
      machine.fields[4] = !!value.field5 ? "yes" : "";
    else  machine.fields[4] = value.field5;
    machine.categories = value.categories.map(c => codeOnly(c));
    // machine.journals = value.journals || [];
    // note that we don't include the attachment onto the DTO here, as the upload is done separately and DTO's are incompatible
  }

  static scanResult(codes: ScanResult, group: FormGroup){
    const modelId = typeof codes.modelId === "string" ? codes.modelId : codes.modelId.code;
    if (codes.modelId)
    group.patchValue({serialNumber: codes.serialNumber, modelId, field3: "Scanned" });
    if (typeof codes.modelId !== "string") {
      MachineMasterComponent.modelSelected(codes.modelId, group);
    }
    group.get("serialNumber").markAsDirty();
    group.get("modelId").markAsDirty();
    group.markAsDirty();
  }

  static modelSelected(model: ModelMasterDto, group: FormGroup) {
    if (model) {
      group.patchValue({
        modelId: model.code,
        name: model.description,
        model
      });
      if (model.categories && model.categories.some(c => !!c)) {
        group.patchValue({
          categories: model.categories || []
        });
        group.get("name").disable();
      } else {
        group.get("name").enable();
      }
    }
  }

  allowClear: boolean = false;
  defaultBrand: string = null;
  tenantId: string = null;
  constructor(
    layoutService: WebLayoutService,
    public productSettings: ProductSettingService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private jobService: JobService,
    private appQuery: AppQuery,
    private viewRef: ViewContainerRef,
    private route: ActivatedRoute,
    @Optional() dialogRef?: MatDialogRef<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: any
  ) {
    super(layoutService, null, dialogRef);
    this.categoryCount = productSettings.stringValue("MachineCategoriesWeb").split(",").length || 2;
    this.showButton = productSettings.booleanValue("AllowAdhocLookups");
    this.allowClear = productSettings.booleanValue("MachineMasterAllowClearModel");
    this.tenantId = this.route.snapshot.params?.tenant || this.appQuery.tenant;
    this.defaultBrand = productSettings.stringValue(`DefaultBrand_${this.tenantId}`) || '';
  }

  get canClearModel(): boolean {
    return this.group?.enabled && this.allowClear;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.group && this.fieldSettings){
      this.applyValidation(this.group, this.fieldSettings);
    }
  }

  get layoutName(): string {
    return `Machine${this.context}`;
  }

  ngOnInit() {
    this.getFieldSettings()
    .subscribe((fs: FieldSettings) => {
      if (this.group && fs) // group will almost always be null until it's passed into the Component as a prop (circular dependency)
        this.applyValidation(this.group, fs);
    });
    if (this.dialogRef) {
      this.dialogRef.updateSize("600px");
    }
  }

  clearModel() {
    this.group.patchValue({ modelId: "", name: "", categories: [] });
    this.categoryControls.controls.forEach(c => c.setValue(""));
  }

  /**
   * Use Given FieldSettings to apply custom Validation settings to certain form fields
   * Supported fields are: modelId, serialNumber, dateOfPurchase, dealerBranchId
   */
   applyValidation(group: FormGroup, fieldSettings: FieldSettings){
    Object.keys(fieldSettings).forEach(field => {
      if(!group.get(field)) return;
      const control = group.get(field);
      const settings = fieldSettings[field];
      if(settings?.validates !== undefined) {
        const useValidation = settings.validates;
        control.setValidators(useValidation ? Validators.required : null);
        control.updateValueAndValidity();
      }
    });
    group.updateValueAndValidity();
  }

  showField(field: string, defaultVisible: boolean = true) {
    if (this.fieldSettings?.[field]?.disabled === undefined) return defaultVisible; // default
    return this.fieldSettings?.[field]?.disabled === false ? true : false;
  }

  protected configureModal(model: MachineMasterDto) {
    if ("disabled" in this.data) {
      if (this.data.disabled)
        this.group.disable();
    }
    this.setFormData(model, this.group);
  }

  setFormData(machine: MachineMasterDto, group: FormGroup) {
    if(this.defaultBrand) {
        machine.categories.splice(0, 1, this.defaultBrand);
    }
    MachineMasterComponent.setFormData(machine, group, this.productSettings);
  }

  getFormData(machine: MachineMasterDto, group: FormGroup) {
    MachineMasterComponent.getFormData(machine, group);
  }

  /**
   * Enable Type Switching between Input fields
   */
  getFieldContext(field: string, defaultType: FieldContextTypes = "text"): FieldContext {
    const type = this.fieldSettings?.[field]?.type || defaultType;
    const readonly = this.fieldSettings?.[field]?.readonly;
    return {
      name: field,
      id: 'machine'+field,
      type,
      lookup: type === 'lookup' && 'machine'+field || undefined,
      readonly: !!readonly, // TODO: set formControl to disabled instead of readonly
    };
  }

  addModel() {
    const model = newModelMaster();
    model.categories = [...this.selectedCategories];
    model.skills = [...this.selectedCategories];
    const modal = this.dialog.open(ModelMasterComponent, { width: '800px' , data: { model }});
    const modelMaster = modal.componentInstance as ModelMasterComponent;
    modelMaster.showSkills = false;// categories will be applied to the skills in this case
    modal.afterClosed().subscribe(result => {
      if (result) {
        this.modelSelected(result);
      }
    });
    return false;
  }

  modelSelected(model: ModelMasterDto) {
    MachineMasterComponent.modelSelected(model, this.group);
    this.jobService.updateAllowedCodes(model);
  }

  scanned(codes: ScanResult) {
    MachineMasterComponent.scanResult(codes, this.group);
  }

  spacer(settings: string[]): boolean {
    return settings.every(s => !!this.productSettings.notLabel(s));
  }

  isPublic(): boolean {
    return this.appQuery.loginStatus === LoginStatus.False;
  }

  /**
   * Writes FileList to Form's Control value (this must be done manually as the normal reactive form binding only has the file's name)
   * @param event input event (with FileList)
   */
  attachmentChanged(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    this.labelImport.nativeElement.innerText = Array.from(files)
    .map(f => f.name)
    .join(', ');

    if (files?.length) {
      this.group.controls.attachment.patchValue(Array.from(files).map(f => ({ file: f, description: ''})));
    } else {
      this.group.controls.attachment.patchValue(null);
    }
  }

  getLookupDisplay() {
    return this.isPublic() ? 'name' : 'all';
  }

  mustShowAllCategories(): boolean {
    // all category context names: show all categories for the following contexts (Master, Job) - not reg
    return this.productSettings.stringValue("MachineShowAllCategoryContexts").split(',').includes(this.context);
  }

  unlockMachine() {
    // query jobs for this machine
    this.jobService.getMachineJobs(this.group.value.machineId).subscribe(jobs => {
      if(jobs.length > 0) {
        // display modal with options to leave as is or edit
        this.launchExistingJobsModal();
      } else {
        this.allowModelEdit();
      }
    });
  }

  get modelUnlocked(): boolean {
    return this.group.enabled;
  }

  allowModelEdit() {
    this.group.enable();
    this.group.get("name").disable();
  }

  editModelReasonText: string = "";
  @Input() showUnlock = false;

  unlockMachineModalComponentRef: ComponentRef<UnlockMachineModalComponent>;
  launchExistingJobsModal() {
    this.unlockMachineModalComponentRef = this.viewRef.createComponent(UnlockMachineModalComponent);
    this.unlockMachineModalComponentRef.instance.unlockMachine.subscribe((reason) => {
      this.allowModelEdit();
      this.addModelEditReasonToMachine(reason);
      this.unlockMachineModalComponentRef.destroy();
    });
  }

  // TODO: add new journal type code to master list
  addModelEditReasonToMachine(reason: string) {
    // this.translate.get("Machine.EditReason").subscribe((text) => {
      this.addMachineJournalEntry(reason, "CHANGEMACHINEREASON");
    // });
  }

  addMachineJournalEntry(text: string, typeId: string, from: string = "", to: string = "") {
    this.addJournal.emit({
      created: new Date(),
      text,
      from,
      to,
      typeId,
      by: this.appQuery.username
    });
  }
}


// MachineJob:
// field2: {
//   type: 'lookup',
//   disabled: false,
// }
// field3: {
//   type: 'lookup',
//   disabled: false,
// }
// field4: {
//   type: 'lookup',
//   disabled: false,
// }

// MachineMaster:
// field2: {
//   type: 'lookup',
//   disabled: false,
//   readonly: true
// }
// field3: {
//   type: 'lookup',
//   disabled: false,
//   readonly: true
// }
// field4: {
//   type: 'lookup',
//   disabled: false,
//   readonly: true
// }
