import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "@env/environment";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AppQuery } from "./app.store";
import { StorageService } from "./services";
import { AuthenticationService } from "./services/authentication.service";
import { convertObjectToDate } from "./utilities/date.utilities";

/**
 * First Level Intercept
 * Fixup Request URL and display a few generic errors when found
 */
@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  private readonly iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
  constructor(
    private appQuery: AppQuery,
    private snackBar: MatSnackBar,
    private storage: StorageService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // return this.appQuery.$tenant.pipe(mergeMap(tt => {
    let newReq = req;
    const tt = this.appQuery.tenant2;
    const tenant = tt ? tt + "/" : "";

    const extra =
      req.url.startsWith("/") ||
      req.url.startsWith("http") ||
      req.url.startsWith("file") ||
      req.url.endsWith("svg")
        ? ""
        : `/api/${tenant}`;
    const base =
      req.url.startsWith("http") ||
      req.url.endsWith("svg") ||
      req.url.startsWith("file")
        ? ""
        : environment.webApi;

    let headers = req.headers;
    if (this.appQuery.language)
      headers = headers.set("Accept-Language", this.appQuery.language);

    newReq = req.clone({
      url: `${base}${extra}${req.url}`,
      headers,
  });

    return next.handle(newReq).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const body = event.body;
            // convertObjectToDate(body);// for some reason this version doesnt do the trick...
            this.convertToDate(body);
          }
        },
        (response: any) => {
          if (response instanceof HttpErrorResponse) {
            // console.log('HTTPError confirmed... processing')
            let errMsg = "";
            if (
              response.status === 404 &&
              response.error &&
              (response.error.error_message === "Company not found." ||
                response.error.error_reason === "invalid_tenant")
            ) {
              this.storage.local.removeItem("tenant");
              // window.location.href = "/";
            }

            // General Errors
            if (
              !!response.error &&
              response.error.error_message /* && showError */
            ) {
              errMsg = response.error
                ? response.error.error_message
                : response.statusText;
              this.snackBar.open(errMsg, null, {
                duration: 7000,
                panelClass: ["bg-warning", "text-dark"]
              });

            // SERVER ERROR
            } else if (!!response.error && response.status === 500) {
              this.snackBar.open(response.error?.title || response.message || "Something went wrong. Please report this error if it persists.", null, {
                duration: 10000,
                panelClass: ["bg-danger", "text-dark"]
              });
            }
          }
        }
      ),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          // console.warn(`HTTPErrorResponse - status: ${err.status} - message: ${err.message}`)
          // return throwError(new Error(`HTTPErrorResponse - status: ${err.status} - message: ${err.message}`));
          return throwError(err);// pass the buck down to the RefreshTokenInterceptor
        }
      })
    );
    // }));
  }

  // Note there is a 'duplicate' version on Date.utilities
  convertToDate(body) {
    if (body === null || body === undefined || typeof body !== "object") {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (this.isIso8601(value)) {
        if(value === '1900-01-01T00:00:00+02:00')// server send incorrect GMT offset for 1900-01-01
          body[key] = new Date('1900-01-01T00:00:00+01:30');
        else
          body[key] = new Date(value);

      } else if (typeof value === "object") {
        this.convertToDate(value);
      }
    }
  }

  isIso8601(value) {
    return value !== null && value !== undefined && this.iso8601.test(value);
  }
}

/**
 * Second Level Interceptor
 * Add Auth Token, force refresh token when possible, throw all other status
 */
@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthenticationService, private appQuery: AppQuery) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(this.addAuthenticationToken(request)).pipe(
      catchError(error => {
        // We don't want to refresh token for some requests like login or refresh token itself
        // So we verify url and we throw an error if it's the case
        if (request.url.includes("token")) {
          // We do another check to see if refresh token failed
          // In this case we want to logout user and to redirect it to login page

          if (request.url.includes("token")) {
            // this.auth.logout();
          }
          // console.warn('Potential issue when issuing token - throw error? - maybe just swallow')
          return throwError(error);
        }

        // If error status is different than 401 we want to skip refresh token
        // So we check that and throw the error if it's the case
        if (error.status !== 401) {
          // console.warn('NON 401 error encountered... throwing');
          return throwError(error);
        }

        // console.warn('Trigger Token Refresh...')
        return this.auth.bigTokenRefresh(error, () => next.handle(this.addAuthenticationToken(request))) as Observable<HttpEvent<any>>;
      })
    );
  }

  addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    const blacklistedRoutes = [/\/connect\//, /floorplanner.com/];// token requests and external api's dont use internal auth token

    // Get access token from Local Storage
    const accessToken = this.auth.getAccessToken();
    // console.log(`TOKEN: ${accessToken.substring(0, 10)}...`);
    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken || blacklistedRoutes.some(route => route.test(request.url)) || !this.appQuery.roles?.length) {
      return request;
    }

    // console.log(`TOKEN added to request... ${request.url}`);
    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: "Bearer " + accessToken
      }
    });
  }
}
