import { Injectable } from "@angular/core";
import {
  AttachmentService,
  BaseAttachmentDto,
  generateCheckDigit,
  ListResultDto,
  ModelMasterDto,
  SearchDto,
} from "@shared/models";
import { Observable } from "rxjs";
import moment from "moment";
import { environment } from "@env/environment";
import { DataService } from "..";
import { Progress, ServiceConfig } from "./data.service";
import { ListDataService } from "./list-data.service";
import { ProductSettingService } from "./product-setting.service";

export type MetaField = {
  name: string,
  type: 'string' | 'number',
  subtype?: 'textarea'
};
// THESE META FIELDS are for EVO (TROJAN) Equipment
const defaultMetaFields: MetaField[] =
[
  {
    name: "ClearanceSize",
    type: "string",
  },
  {
    name: "Features",
    type: "string",
    subtype: "textarea"
  },
  {
    name: "GrossWeight",
    type: "number",
  },
  {
    name: "NettWeight",
    type: "number",
  },
  {
    name: "Price",
    type: "number",
  },
  {
    name: "Size",
    type: "string",
  },
  {
    name: "Sticker",
    type: "string",
  },
];

@Injectable({
  providedIn: "root",
})
export class ModelService extends ListDataService implements AttachmentService {
  listName = "modelmaster";

  constructor(
    protected config: ServiceConfig,
    dataService: DataService
  ) {
    super(config, dataService);
  }

  /**
   * Get full (server limited) list of Models
   * @returns ModelMasterDto[]
   */
  getModels(limit = 50): Observable<ModelMasterDto[]> {
    return this.getList<ModelMasterDto>(this.listName, limit);
  }

  /**
   * Returns the Model List for Lookup Lists
   * @param categories array of Model categories
   * @param query string search text
   * @param extraFilters additional filter to apply after the categories
   * @returns Observable ModelMasterDto
   */
  queryModels(
    categories: string[] | null,
    query: string,
    extraFilters?: string
  ): Observable<ModelMasterDto[]> {
    return this.queryList<ModelMasterDto>(
      this.listName,
      [
        ...(categories ? categories : []),
        ...(extraFilters ? [extraFilters] : []),
      ],
      query
    );
  }

  searchModels(query: SearchDto): Observable<ListResultDto<ModelMasterDto>> {
    return this.searchList<ModelMasterDto>(this.listName, query);
  }

  getModel(modelId: string): Observable<ModelMasterDto> {
    return this.getListItem(this.listName, modelId);
  }

  /**
   * Posts a new Model to the List
   * @param model ModeMasterDto the new model data to save
   * @returns Observable ModelMasterDto
   */
  addModel(model: ModelMasterDto): Observable<ModelMasterDto> {
    return this.addListItem<ModelMasterDto>(this.listName, model);
  }

  updateModel(
    modelId: string,
    model: ModelMasterDto
  ): Observable<ModelMasterDto> {
    return this.updateListItem<ModelMasterDto>(
      this.listName,
      modelId,
      model
    );
  }

  getMetaFields(productSettings: ProductSettingService): MetaField[] {
    const metaFieldsString = productSettings.stringValue('ModelDetailsAdminMetaFields');
    let metaFields = defaultMetaFields;
    try{
      metaFields = JSON.parse(metaFieldsString);
    } catch (e) {
      console.warn('Meta Fields not Defined - using defaults');
    }
    return metaFields;
  }

  //region ATTACHMENTS
  uploadAttachment(parentId: string, file: File, description: string, progress: Progress, _generateCheckDigit: boolean, extras?: any, typeId?: string): Observable<BaseAttachmentDto> {
    const formData = new FormData();
    formData.append(description, file, file.name);
    formData.append('lastModified', moment(file.lastModified).format()); // moment's default format is ISO 8601
    const url = `machines/model/${parentId}/attachment${typeId && `/${typeId}` || ''}`;
    return this.http
      .post<BaseAttachmentDto>(url, formData, {
        reportProgress: true,
        observe: "events",
      })
      .pipe(this.uploading(progress));
  }
  downloadAttachment(parentId: string, attachmentId: string, extras?: any): Observable<Blob> {
    return this.http.get(
      this.downloadLink({attachmentId} as BaseAttachmentDto, parentId, extras),
      {
        responseType: "blob",
      }
    );
  }
  deleteAttachment(parentId: string, attachmentId: string, extras?: any): Observable<any> {
    return this.http.delete(
      this.downloadLink({attachmentId} as BaseAttachmentDto, parentId, extras)
    );
  }
  downloadLink(attachment: BaseAttachmentDto, parentId?: string, extras?: any): string {
    let parentPart = '';
      if (parentId) {
        parentPart = this.safeEncode(parentId) + "/";
      }
      return `machines/model/${parentId}/attachment/${attachment.attachmentId}`;
  }
  fullUrl(attachment: BaseAttachmentDto, parentId?: string, extras?: any): string {
    return `${environment.webApi}/api/${this.appQuery.tenant2}/${this.downloadLink(attachment, parentId, extras)}`;
  }
  //endregion
}
