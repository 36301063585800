<span mat-dialog-title>{{ tip?.link }}</span>
<mat-dialog-content>
    <ngb-carousel #carousel *ngIf="tips" (slide)="slide($event)">
        <ng-template *ngFor="let tip of tips" ngbSlide [id]="tip.attachmentId">
            <img [src]="downloadLink(tip)" [alt]="tip.source">
        </ng-template>
    </ngb-carousel>
</mat-dialog-content>
<mat-dialog-actions>
    <button cdkFocusInitial type="button" mat-button mat-dialog-close (click)="close()">Close</button>
</mat-dialog-actions>