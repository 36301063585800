import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from "@angular/core";
import { MatSelectionListChange } from "@angular/material/list";
import { MatMenuTrigger } from "@angular/material/menu";
import { PublishSubscribe } from "@modules/common";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, from } from "rxjs";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { LookupListService } from "@services";
import { LookupObjectDto } from "@modules/models";
import { TableColumn } from "..";
import { TreeNode } from "../column-chooser/column-chooser.component";

/**
 * We accept a list of text items that are written as an array of strings into the form
 */
@Component({
  selector: "abi-array-input",
  templateUrl: "./array-input.component.html",
  // styleUrls: ["./array-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ArrayInputComponent),
      multi: true,
    }
  ]
})
export class ArrayInputComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() separator: string = ",";

  displayValue = "";
  updateValue(value: string[]) {
    if (Array.isArray(value)) {
      this.displayValue = value.join(this.separator);
    }
    console.log('updateValue', value);
    this.onChange(value);
  }

  inputChanged(value: string) {
    const valueArray = value.split(this.separator);
    this.updateValue(valueArray);
  }

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
  ) {}

  onChange = (_: any) => {};

  onTouched = () => {};

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
  }

  // BOILERPLATE
  protected setProperty(key: string, value: any): void {
    this._renderer.setProperty(this._elementRef.nativeElement, key, value);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.setProperty('disabled', isDisabled);
  }

  writeValue(value: string[]) {
    // this.displayValue = value.join(this.separator);
    this.updateValue(value);
  }
}
