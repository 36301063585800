import { Injectable } from "@angular/core";
import { AppQuery, LoginStatus } from "@modules/common/app.store";
import { companyThenName, CustomerMasterDto, fullName, JobDto, ModelMasterDto, newSearchDto, primaryContact, ResourceMasterDto, StockMasterDto, UserDto } from "@modules/models";
import * as Sentry from "@sentry/angular-ivy";
import { CustomerService, JobService, ModelService, ProductSettingService, ResourceService, StockService, UserService } from "@services";
import 'array-object-filter-unique';
import { combineLatest } from "rxjs";
import { debounceTime } from "rxjs/operators";

export interface SearchResult {
  id: string;
  name: string;
  entity: string;
  baseUrl: string;
  icon: string;
}

@Injectable({
  providedIn: "root"
})
export class SearchService {
  entityIconMap = {
    job: "fa fa-briefcase",
    customer: "fas fa-address-card",
    model: "fas fa-tv",
    stock: "fas fa-box",
    resource: "fas fa-user",
    user: "fas fa-user-cog",
  };
  currentSearchTerm: string = "";
  currentSearchResult: SearchResult[] = [];
  previousSearchTerms: string[] = [];
  previousSearchResults: SearchResult[] = [];

  // defaultSearchResultEntities = ['job', 'customer', 'resource', 'user'];
  defaultSearchResultEntities = ['job', 'customer', 'model', 'stock', 'resource', 'user'];
  searchResultEntities = this.defaultSearchResultEntities;
  constructor(
    private jobService: JobService,
    private customerService: CustomerService,
    private modelService: ModelService,
    private stockService: StockService,
    private resourceService: ResourceService,
    private userService: UserService,
    private appQuery: AppQuery,
    private productSettingService: ProductSettingService
  ){
    // CACHE MANAGEMENT
    combineLatest([
      this.appQuery.$tenant2,
      this.appQuery.$loginStatus,
    ])
    .pipe(debounceTime(200))
    .subscribe(([tenant, loggedin]) => {
      if (tenant && loggedin === LoginStatus.True) {
        // process allowed search results
        this.searchResultEntities = this.productSettingService.arrayValue('SearchResultEntities', this.defaultSearchResultEntities);
      } else {
        this.clearCurrentSearch();
        this.clearPreviousSearch();
      }
    });
  }

  clearPreviousSearch() {
    this.previousSearchTerms = [];
    this.previousSearchResults = [];
  }

  clearCurrentSearch() {
    this.currentSearchTerm = "";
    this.currentSearchResult = [];
  }

  getBaseJobSeachDto() {
    return {
      ...newSearchDto(),
      filters: {
        closed: 'false'
      },
      orderBy: ['!jobId'],
      pageNumber: 1,
      pageSize: 5
    };
  }

  async searchJobs(term: string) {
    try {
      const searchByJob = this.getBaseJobSeachDto();
      searchByJob.filters['jobId'] = term;
      const searchByContact = this.getBaseJobSeachDto();
      searchByContact.filters['contact'] = term;
      const [jobResults, contactResults] = await Promise.all(
        [
          this.jobService.queryJobs(searchByJob).toPromise(),
          this.jobService.queryJobs(searchByContact).toPromise()
        ]
      );
      const response = [
        ...jobResults.items, //.map(this.mapToSearchResult('job',[this.appQuery.tenant])),
        ...contactResults.items, //.items.map(this.mapToSearchResult('job',[this.appQuery.tenant]))
      ];
      return response.filterUniqueObject({ attribute: 'jobId' });
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  getBaseCustomerSearchDto() {
    return {
      ...newSearchDto(),
      filters: {
        statusId: 'A'
      },
      orderBy: ['!id'],
      pageNumber: 1,
      pageSize: 5
    };
  }

  async searchCustomers(term: string) {
    try {
      const searchById = this.getBaseCustomerSearchDto();
      searchById.filters['customerId'] = term;
      const searchByName = this.getBaseCustomerSearchDto();
      searchByName.filters['name'] = term;
      const searchByPhone = this.getBaseCustomerSearchDto();
      searchByPhone.filters['primaryContact.telephone'] = term;
      // const customersQuery = this.customerService.searchCustomers(searchByCustomer).toPromise()
      // const customersQuery = this.customerService.queryCustomers(term, null, 10).toPromise()
      const [idResults, nameResults, phoneResults] = await Promise.all(
        [
          this.customerService.searchCustomers(searchById).toPromise(),
          this.customerService.searchCustomers(searchByName).toPromise(),
          this.customerService.searchCustomers(searchByPhone).toPromise()
        ]
      );
      const response = [
        ...idResults.items, //.map(this.mapToSearchResult('job',[this.appQuery.tenant])),
        ...nameResults.items, //.items.map(this.mapToSearchResult('job',[this.appQuery.tenant]))
        ...phoneResults.items
      ];
      return response.filterUniqueObject({ attribute: 'id' });
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  getBaseModelSearchDto() {
    return {
      ...newSearchDto(),
      filters: {
        active: true
      },
      orderBy: ['code'],
      pageNumber: 1,
      pageSize: 5
    };
  }

  async searchModels(term: string) {
    try {
      const searchByModel = this.getBaseModelSearchDto();
      searchByModel.filters['code'] = term;
      const searchByDescription = this.getBaseModelSearchDto();
      searchByDescription.filters['description'] = term;
      const [modelResults, descriptionResults] = await Promise.all(
        [
          this.modelService.searchModels(searchByModel).toPromise(),
          this.modelService.searchModels(searchByDescription).toPromise()
        ]
      );
      const response = [
        ...modelResults.items,
        ...descriptionResults.items
      ];
      return response.filterUniqueObject({ attribute: 'code' });
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  getBaseStockSearchDto() {
    return {
      ...newSearchDto(),
      filters: {
        active: true
      },
      orderBy: ['code'],
      pageNumber: 1,
      pageSize: 5
    };
  }

  async searchStocks(term: string) {
    try {
      const searchByStock = this.getBaseStockSearchDto();
      searchByStock.filters['code'] = term;
      const searchByDescription = this.getBaseStockSearchDto();
      searchByDescription.filters['description'] = term;
      const [stockResults, descriptionResults] = await Promise.all(
        [
          this.stockService.queryStock(searchByStock).toPromise(),
          this.stockService.queryStock(searchByDescription).toPromise()
        ]
      );
      const response = [
        ...stockResults.items,
        ...descriptionResults.items
      ];
      return response.filterUniqueObject({ attribute: 'code' });
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  getBaseResourceSearchDto() {
    return {
      ...newSearchDto(),
      filters: {
        active: true
      },
      orderBy: ['code'],
      pageNumber: 1,
      pageSize: 5
    };
  }

  async searchResources(term: string) {
    try {
      const searchById = this.getBaseResourceSearchDto();
      searchById.filters['code'] = term;
      const searchByFirstName = this.getBaseResourceSearchDto();
      searchByFirstName.filters['contact.firstName'] = term;
      const searchByLastName = this.getBaseResourceSearchDto();
      searchByLastName.filters['contact.lastName'] = term;
      const searchByPhone = this.getBaseResourceSearchDto();
      searchByPhone.filters['contact.mobile'] = term;
      const searchByEmail = this.getBaseResourceSearchDto();
      searchByEmail.filters['contact.emailAddress'] = term;
      const [idResults, firstNameResults, lastNameResults, phoneResults, emailResults] = await Promise.all(
        [
          this.resourceService.searchResources(searchById).toPromise(),
          this.resourceService.searchResources(searchByFirstName).toPromise(),
          this.resourceService.searchResources(searchByLastName).toPromise(),
          this.resourceService.searchResources(searchByPhone).toPromise(),
          this.resourceService.searchResources(searchByEmail).toPromise()
        ]
      );
      const response = [
        ...idResults.items,
        ...firstNameResults.items,
        ...lastNameResults.items,
        ...phoneResults.items,
        ...emailResults.items
      ];
      return response.filterUniqueObject({ attribute: 'code' });
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  getBaseUserSearchDto() {
    return {
      ...newSearchDto(),
      filters: {
      },
      orderBy: ['userId'],
      pageNumber: 1,
      pageSize: 5
    };
  }
  async searchUsers(term: string) {
    try {
      const searchByUserId = this.getBaseUserSearchDto();
      searchByUserId.filters['userId'] = term;
      const searchByDescription = this.getBaseUserSearchDto();
      searchByDescription.filters['description'] = term;
      const searchByEmail = this.getBaseUserSearchDto();
      searchByEmail.filters['emailAddress'] = term;
      // const searchByEmail = this.getBaseUserSearchDto();
      // searchByEmail.filters['contact.emailAddress'] = term;
      const [firstNameResults, lastNameResults, emailResults] = await Promise.all(
        [
          this.userService.searchLogins(searchByUserId).toPromise(),
          this.userService.searchLogins(searchByDescription).toPromise(),
          this.userService.searchLogins(searchByEmail).toPromise(),
          // this.resourceService.searchResources(searchByEmail).toPromise()
        ]
      );
      const response = [
        ...firstNameResults.items,
        ...lastNameResults.items,
        ...emailResults.items
      ];
      return response.filterUniqueObject({ attribute: 'userId' });
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  savePreviousSearch() {
    this.previousSearchTerms.push(this.currentSearchTerm);
    this.previousSearchResults = this.currentSearchResult;
  }

  getPreviousSearch(): {term: string, results: SearchResult[]} {
    return {term: this.previousSearchTerms[this.previousSearchTerms.length-1], results: this.previousSearchResults};
  }

  maxSearchResults = 10;
  async search(term: string): Promise<SearchResult[]> {
    this.currentSearchTerm = term;
    const allResults = await Promise.all([
      ...this.searchResultEntities.map((entity) => {
        switch (entity) {
          case 'job':
            return this.searchJobs(term);
          case 'customer':
            return this.searchCustomers(term);
          case 'model':
            return this.searchModels(term);
          case 'stock':
            return this.searchStocks(term);
          case 'resource':
            return this.searchResources(term);
          case 'user':
            return this.searchUsers(term);
          default:
            return Promise.resolve([]);
        }
      })
      // this.searchJobs(term),
      // this.searchCustomers(term),
      // this.searchResources(term),
      // this.searchUsers(term),
      // this.searchModels(term),
    ]);
    const resultsMap: Map<string, any[]> = new Map();
    this.searchResultEntities.forEach((entity, index) => {
      resultsMap.set(entity, allResults[index]);
    });
    // const jobResultCount = resultsMap.get('job').length;
    // const customerResultCount = resultsMap.get('customer').length;
    // const resourceResultCount = resultsMap.get('resource').length;
    // const userResultCount = resultsMap.get('user').length;
    // // @todo calculate the number of results to return based on the number of results from each entity
    // const response = [
    //   ...jobs.map(this.mapToSearchResult('job', [this.appQuery.tenant])).slice(0, (customerResultCount + resourceResultCount + userResultCount) > 5 ? 5 : 10),
    //   ...customers.map(this.mapToSearchResult('customer', [this.appQuery.tenant])).slice(0, (jobResultCount + resourceResultCount + userResultCount) > 5 ? 5 : 10),
    //   ...resources.map(this.mapToSearchResult('resource', [this.appQuery.tenant])).slice(0, (customerResultCount + jobResultCount + userResultCount) > 5 ? 5 : 10),
    //   ...users.map(this.mapToSearchResult('user', [this.appQuery.tenant])).slice(0, (customerResultCount + jobResultCount + resourceResultCount) > 5 ? 5 : 10)
    // ];
    const resultKeys = Array.from(resultsMap.keys());
    const response = resultKeys
    .map((entity) => {
      return resultsMap.get(entity)
      .map(this.mapToSearchResult(entity, [this.appQuery.tenant]))
      // @TODO: work out a clever way to slice the results based on the number of results from each entity
      .slice(0, resultKeys.filter((key) => key !== entity).reduce((acc, key) => acc + resultsMap.get(key).length, 0) > 10 ? 5 : 10);
    })
    .flat()
    .slice(0, this.maxSearchResults);

    this.currentSearchResult = response;
    return response;
  }

  mapToSearchResult(entity: string, baseUrl: string[]) {
    return (item: JobDto | CustomerMasterDto | ModelMasterDto | StockMasterDto | ResourceMasterDto | UserDto) => {
      switch (entity) {
        case 'job':
          return this.mapJobToSearchResult(item as JobDto, baseUrl);
        case 'customer':
          return this.mapCustomerToSearchResult(item as CustomerMasterDto, baseUrl);
        case 'model':
          return this.mapModelToSearchResult(item as ModelMasterDto, baseUrl);
        case 'stock':
          return this.mapStockToSearchResult(item as StockMasterDto, baseUrl);
        case 'resource':
          return this.mapResourceToSearchResult(item as ResourceMasterDto, baseUrl);
        case 'user':
          return this.mapUserToSearchResult(item as UserDto, baseUrl);
        default:
          return null;
      }
    };
  }


  mapJobToSearchResult(job: JobDto, urlPath: string[]): SearchResult {
    return {
      id: job.jobId,
      name: `${companyThenName(job.contact)} - ${job.typeId} - ${job.machine.modelId}`,
      entity: 'job',
      baseUrl: urlPath.join('/') + '/job/',
      icon: this.entityIconMap['job']
    };
  }

  // mapCustomerToSearchResult(customer: CustomerLookupDto, urlPath: string[]): SearchResult {
  mapCustomerToSearchResult(customer: CustomerMasterDto, urlPath: string[]): SearchResult {
    return {
      id: customer.id,
      name: `${customer.name || fullName(primaryContact(customer))} - ${ primaryContact(customer)?.mobile || primaryContact(customer)?.officeHours || primaryContact(customer)?.afterHours || '' }`,
      entity: 'customer',
      baseUrl: urlPath.join('/') + '/customer/',
      icon: this.entityIconMap['customer']
    };
  }

  mapModelToSearchResult(model: ModelMasterDto, urlPath: string[]): SearchResult {
    return {
      id: model.code,
      name: `${model.code} - ${model.description}`,
      entity: 'model',
      baseUrl: urlPath.join('/') + '/admin/machine/models/',
      icon: this.entityIconMap['model']
    };
  }

  mapStockToSearchResult(stock: StockMasterDto, urlPath: string[]): SearchResult {
    return {
      id: stock.code,
      name: `${stock.code} - ${stock.description}`,
      entity: 'stock',
      baseUrl: urlPath.join('/') + '/warehouse/stock/',
      icon: this.entityIconMap['stock']
    };
  }

  mapResourceToSearchResult(resource: ResourceMasterDto, urlPath: string[]): SearchResult {
    return {
      id: resource.code,
      name: `${resource.contact.firstName} ${resource.contact.lastName} - ${resource.contact.mobile || resource.contact.officeHours || resource.contact.afterHours} - ${resource.contact.emailAddress}`,
      entity: 'resource',
      baseUrl: urlPath.join('/') + '/admin/resource/',
      icon: this.entityIconMap['resource']
    };
  }

  mapUserToSearchResult(user: UserDto, urlPath: string[]): SearchResult {
    return {
      id: user.userId,
      name: `${user.userId} - ${user.description} - ${user.emailAddress}`,
      entity: 'user',
      baseUrl: urlPath.join('/') + '/admin/user/',
      icon: this.entityIconMap['user']
    };
  }
}
