import { Injectable } from "@angular/core";
import { EntityState, EntityStore, QueryEntity, StoreConfig } from "@datorama/akita";
import { WebLayoutDto } from "@shared/models";
import { combineLatest, Observable } from "rxjs";
import { auditTime, filter, mergeMap, tap } from "rxjs/operators";
import { DataService, ServiceConfig } from "../";
import { LoginStatus } from "../app.store";
import { ColsData } from "../components";

export const CUSTOM_LAYOUT_ID = 'CustomLayout';

export interface WebLayout {
  formId: string;
  layouts: WebLayoutDto[];
}

export interface WebLayoutState extends EntityState<WebLayout> { }

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "webLayout", idKey: "formId" })
export class WebLayoutStore extends EntityStore<WebLayoutState, WebLayout> {
  constructor() {
    super();
  }
}

@Injectable({ providedIn: "root" })
export class WebLayoutQuery extends QueryEntity<WebLayoutState, WebLayout> {
  constructor(protected store: WebLayoutStore) {
    super(store);
  }
}
@Injectable({
  providedIn: "root"
})
export class WebLayoutService extends DataService {
  loading$: Observable<boolean>;
  layouts: WebLayoutQuery;

  get defaultLayoutName(): string {
    return "Default";
  }

  constructor(config: ServiceConfig, private store: WebLayoutStore, private query: WebLayoutQuery) {
    super(config);

    this.loading$ = this.query.selectLoading();
    this.layouts = this.query;
    this.store.setLoading(true);

    combineLatest([this.appQuery.$tenant2, this.appQuery.$loginStatus])
    .pipe(
      this.notDisposed(),
      auditTime(100),
      filter(([t, l]) => !!t && LoginStatus.True === l),
      tap(tt => {
        this.store.remove();
        this.store.setLoading(true);
      }),
      mergeMap(
        () => this.getLayouts())
      )
      .subscribe(layouts => {
        this.store.add(Object.keys(layouts).map(s => ({ formId: s, layouts: layouts[s] })));
        this.store.setLoading();
      });
  }

  getWebLayouts(pageId: string): WebLayoutDto[] {
    const data = this.query.getEntity(pageId);
    return data ? data.layouts : null;
  }

  getPageLayouts(pageId: string): Observable<WebLayoutDto[]> {
    return this.http.get<WebLayoutDto[]>(`users/layouts/${pageId}`);
  }

  getLayouts(): Observable<{ [page: string]: WebLayoutDto[] }> {
    return this.http.get<{ [page: string]: WebLayoutDto[] }>(`users/layouts/`);
  }

  updateLayout(pageId: string, layout: WebLayoutDto): Observable<WebLayoutDto> {
    return this.http.put<WebLayoutDto>(`users/layouts/${pageId}/${layout.layoutId}`, layout);
  }

  createLayout(pageId: string, layout: WebLayoutDto): Observable<WebLayoutDto> {
    return this.http.post<WebLayoutDto>(`users/layouts/${pageId}`, layout);
  }

  deleteLayout(pageId: string, layoutId: string): Observable<any> {
    return this.http.delete(`users/layouts/${pageId}/${layoutId}`);
  }

  getSessionLayout(pageId: string): ColsData {
    return JSON.parse(sessionStorage.getItem(pageId));
  }

  clearSessionLayout(pageId: string) {
    sessionStorage.removeItem(pageId);
  }

  setSessionLayout(pageId: string, layout: ColsData) {
    sessionStorage.setItem(pageId, JSON.stringify(layout));
  }

  setSessionData(pageId: string, data: any) {
    sessionStorage.setItem(pageId, JSON.stringify(data));
  }

  getSessionData(pageId: string): any {
    return JSON.parse(sessionStorage.getItem(pageId));
  }
}

