import { Component, Input, OnInit } from "@angular/core";
import XLSX from "xlsx";
import { Subject } from "rxjs";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { xlSerialToJsDate } from "@modules/common/utilities/date.utilities";
import { ProductSettingService, WebLayoutService } from "@services";
import { BaseModal } from "../base-modal";
import { RowClickHandler, TableColumn } from "../ability-table/ability-table.component";

@Component({
  selector: "abi-sheet-importer",
  templateUrl: "./sheet-importer.component.html",
  styleUrls: ["./sheet-importer.component.scss"]
})
export class SheetImporterComponent extends BaseModal<any> implements OnInit {
  @Input() submitText: string = "Submit";
  @Input() columnSettingKey: string = "StockImportColumns";
  @Input() modalTitle: string = "Import Sheet";
  @Input() validUploadTypes: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv', 'application/vnd.oasis.opendocument.spreadsheet'];
  constructor(
    layoutService: WebLayoutService,
    protected activeModal: NgbActiveModal,
    private productSettings: ProductSettingService){
    super(layoutService, activeModal);
  }
  sheetFile: File;
  sheetNames: string[] = [];
  sheetName: string = "";

  requiredSheetColumns: string[] = ["StockId", "Date", "Reference", "Quantity"];// default values
  ngOnInit(): void {
    // throw new Error("Method not implemented.");
    this.requiredSheetColumns = this.productSettings.arrayValue(this.columnSettingKey, this.requiredSheetColumns);
  }

  // ...
  getFormData(model: any, group: any): any {
  }

  fileMessage= "";
  selectFile(event) {
    // this.fileMessage = "";
    const fileList: FileList = event.target.files;
    if(!fileList.length) {
      this.cancel();
    }
    const firstSelectedFile = fileList[0];// only select the first file (only allow single files at a time)
    if (
      !this.validUploadTypes.some((t) => firstSelectedFile.type.includes(t))
    ) {
      this.fileMessage = `Invalid File/Type selected: ${firstSelectedFile.type || 'Unknown'}`;
      // this.loggingService.logException(this.fileMessage);
      // this.cancel();
      this.sheetFile = null;
    } else {
      this.sheetFile = firstSelectedFile;
      // this.description = this.defaultDescription;
      // read file's sheets and first sheet's data
      this.initDataGrid();
    }
  }

  colsObs: Subject<TableColumn[]> = new Subject();
  columns: TableColumn[] = [];
  rowJson: any[] = [];
  rows: any[] = [];
  workbook: XLSX.WorkBook;
  async initDataGrid(){
    this.rowJson = []; // reset data grid
    /* get first file */
    const file = this.sheetFile;
    /* get raw data */
    const data = await file.arrayBuffer();
    /* data is an ArrayBuffer */
    this.workbook = XLSX.read(data);
    this.sheetNames = this.workbook.SheetNames;
    this.sheetName = this.sheetNames[0];
    this.updateSheet();
  }

  excelDateToJSDate(serial: number) {
    return typeof serial === 'number' ? xlSerialToJsDate(serial) : serial;
  }

  dateColumns: string[] = ["Date"];
  sheetMessage: string = "";
  updateSheet() {
    this.sheetMessage = "";
    this.rowJson = XLSX.utils.sheet_to_json(this.workbook.Sheets[this.sheetName]);
    if(!this.rowJson?.length) {
      this.sheetMessage = "No data found in sheet";
      return;
    }
    // Must convert excel dates to JS dates
    this.rowJson.forEach((r) => {
      this.dateColumns.forEach((c) => {
        r[c] = this.excelDateToJSDate(r[c]);
      });
    });
    // console.log("Sheet Data", this.rowJson);
    // Grab the first row and use it to build the columns
    // this.columns = Object.keys(this.rowJson[0])
    this.columns = this.requiredSheetColumns
    // .filter(k => this.requiredSheetColumns.includes(k))
    .map((k) => {
      return {
        field: k,
        header: k,
        type: "text",
        skipFilter: true,
        static: true,
        converter: (v) => v[k]// this will bypass the 'dotValueGetter' and simply return whats in the field
      };
    });
    // Validate Columns against the required columns
    if(this.requiredSheetColumns.length > 0){
      const missingColumns = this.requiredSheetColumns.filter((c) => !this.columns.some((col) => col.field === c));
      if(missingColumns.length > 0){
        this.sheetMessage = `Missing required columns: ${missingColumns.join(", ")}`;
      }
    }

    this.selectedRow = 0;
    this.endingRow = this.rowJson.length - 1;
  }

  setSheet(sheetName: string) {
    this.sheetName = sheetName;
    this.updateSheet();
  }

  close(){
    this.cancel();
  }

  canClickRow(row: any) {
    return true;
  }

  selectedRow: number = 0;
  endingRow: number = 0;
  rowClickHandler(rowEvent: RowClickHandler<any>){
    const { row, index, event } = rowEvent;
    if(this.canClickRow(row)){
      event.stopPropagation();
      this.selectedRow = index;
    }
  }

  getTrClass() {
    return (row: any, index: number) => {
    // return "selected "+"index_"+index;
     return this.selectedRow === index ? "table-primary" : "";
    };
  }

  accepted() {
    return this.rowJson.slice(this.selectedRow);
  }

  canSubmit() {
    return !this.sheetMessage && this.rowJson.slice(this.selectedRow).length > 0;
  }

  static openDialog(ngbModal: NgbModal, requiredColumns: string[], dateColumns: string[], onAfterDismiss: (result) => void, onAfterUpdated: (result) => void){
    const modalRef = ngbModal.open(SheetImporterComponent, {size: "xl"});
    const componentInstance = (modalRef.componentInstance as SheetImporterComponent);
    componentInstance.requiredSheetColumns = dateColumns;
    componentInstance.dateColumns = dateColumns;
    componentInstance.modalTitle = `Sheet Importer`;
    componentInstance.submitText = `Confirm Import`;
    // componentInstance.closeModalAfterSave = true;
    modalRef.dismissed.subscribe((val) => {
      if(val)
        onAfterDismiss(val);
    });
    modalRef.closed.subscribe((val) => {
      if(val) {
        onAfterUpdated(val);
      }
    });
  }
}
