import { isPlatformServer } from "@angular/common";
import { HttpClient, HttpEvent, HttpEventType, HttpParams } from "@angular/common/http";
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from "@angular/core";
import { StateKey, TransferState } from "@angular/platform-browser";
import { LookupListEx, LookupObjectDto, SearchDto } from "@shared/models";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppQuery, AppStore } from "../app.store";
import { Disposable } from "../disposable";
import { CustomHttpParamEncoder } from "../utilities/http.utilities";
import { Storage, StorageService } from "./storage.service";

export const ADD_TO_HOMESCREEN = new InjectionToken<BehaviorSubject<any>>(
  "AddToHomeScreen"
);

export type Progress = (value: number) => void;

@Injectable({
  providedIn: "root"
})
export class ServiceConfig {
  constructor(
    public http: HttpClient,
    @Inject(PLATFORM_ID) public platformId: string,
    public storage: StorageService,
    public serverState: TransferState,
    public appStore: AppStore,
    public appQuery: AppQuery,
  ) {}
}

export class Encoder {
  static allowToPass = /^[=]?[0-9a-zA-Z*_-]+[=]*$/;// character match includes already-encoded strings
  /**
   * Only for use of encoding distinct values for query string
   * ie: not for entiure URLs
   */
  static safeEncode(value: string | string[]){
    if (!value)
      return "";
    if (Array.isArray(value)) {
      return [...value.map(v => Encoder.safeEncode(v))];
    } else {
      if (value.match(Encoder.allowToPass)) {
        return value;
      } else {
        return "=" + btoa(value);
      }
    }
  }

  static safeDecode(value: string) {
    if (!value)
      return "";
    if (value.startsWith("=")) {
      return atob(value.substring(1));
    } else {
      return value;
    }
  }


  static urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, "+").replace(/_/g, "/");
    switch (output.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        output += "==";
        break;
      }
      case 3: {
        output += "=";
        break;
      }
      default: {
        throw new Error("Illegal base64url string!");
      }
    }
    return Encoder.b64DecodeUnicode(output);
  }

  // credits for decoder goes to https://github.com/atk
  static b64decode(str: string): string {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    let output = "";

    str = String(str).replace(/=+$/, "");

    if (str.length % 4 === 1) {
      throw new Error(
        "'atob' failed: The string to be decoded is not correctly encoded."
      );
    }

    for (
      // initialize result and counters
      let bc = 0, bs: any, buffer: any, idx = 0;
      // get next character
      // eslint-disable-next-line no-cond-assign
      (buffer = str.charAt(idx++));
      // character found in table? initialize bit storage and add its ascii value;
      // eslint-disable-next-line no-bitwise
      ~buffer &&
        (
          // eslint-disable-next-line no-cond-assign
          (bs = bc % 4 ? bs * 64 + buffer : buffer),
          // and if not first of each 4 characters,
          // convert the first 8 bits to one ascii character
          bc++ % 4
        )
        // eslint-disable-next-line no-bitwise
        ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
        : 0
    ) {
      // try to find character in table (0-63, not found => -1)
      buffer = chars.indexOf(buffer);
    }
    return output;
  }

  static b64DecodeUnicode(str: any) {
    return decodeURIComponent(
      Array.prototype.map
        .call(this.b64decode(str), (c: any) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  }
}

@Injectable({
  providedIn: "root"
})
export class DataService extends Disposable {
  private storage: StorageService;
  protected platformId: string;
  protected http: HttpClient;
  protected serverState: TransferState;
  protected appStore: AppStore;
  protected appQuery: AppQuery;

  constructor(config: ServiceConfig) {
    super();
    this.http = config.http;
    this.platformId = config.platformId;
    this.storage = config.storage;
    this.serverState = config.serverState;
    this.appStore = config.appStore;
    this.appQuery = config.appQuery;
  }

  protected local(): Storage {
    return this.storage.local;
  }

  protected session(): Storage {
    return this.storage.session;
  }

  public safeEncode(value: string | string[]) {
    return Encoder.safeEncode(value);
  }

  public safeDecode(value: string) {
    return Encoder.safeDecode(value);
  }

  protected extractLookupData<T extends LookupObjectDto>(
    res: T[],
    filter: (sm: T) => boolean = null
  ): LookupListEx<T> {
    if (filter) {
      res = res.filter(filter);
    }
    return new LookupListEx<T>(res);
  }

  /**
   * @deprecated setting server state keys no longer used
   * @param key any
   * @param value any
   */
  private saveOnServer(key: StateKey<any>, value: any) {
    if (isPlatformServer(this.platformId)) {
      this.serverState.set(key, value);
    }
  }

  /**
   * @deprecated File download functoin no longer used
   * @param data any
   * @param type mime type
   */
  downLoadFile(data: any, type: string) {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed === "undefined") {
      alert("Please disable your Pop-up blocker and try again.");
    }
  }

  public uploading(progress: Progress) {
    return <T>(source: Observable<T>) =>
      source.pipe(map((aEvent: any) => {
        const event: HttpEvent<any> = aEvent;
        if (event.type === HttpEventType.UploadProgress) {
          const pValue = Math.round(event.loaded / event.total * 100);
          progress(pValue);
          return null;
        }
        else if (event.type === HttpEventType.Response) {
          return event.body;
        }
      }));
  }

  public searchQueryToParams(query: SearchDto, filtersOnly = false, encodeSafely = true): HttpParams {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    if (query && !filtersOnly)
      params = params.append("pageNumber", query.pageNumber)
      .append("pageSize", query.pageSize)
      .append("orderBy", query.orderBy[0] || "");

    for (const key in query.filters) {
      if (query.filters.hasOwnProperty(key)){
        const value = query.filters[key];
        if(Array.isArray(value) && value.length > 0) {
          params = params.append(key, `any(${value.map(v => encodeSafely && this.safeEncode(v) || v).join(",")})`);
        } else {
          // numbers, strings, booleans
          const stringValue = value?.toString() || null;
          if (stringValue) // only add value if there is any valid string value
          params = params.append(key, encodeSafely && this.safeEncode(stringValue) || stringValue );
        }
      }
    }
    return params;
  }

  // Turn any Object into an HttpParams
  public objectToParams(obj: any) {
    let httpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    Object.keys(obj).forEach((v, i) => {
      if(obj[v])
        httpParams = httpParams.append(v, obj[v]);
    });
    return httpParams;
  }

  async executeSequentially(promiseFactories: (() => Promise<any>)[] = []) {
    const results = [];
    for (const pf of promiseFactories) {
      results[results.length] = await pf();
    }
    return results;
  }
}
