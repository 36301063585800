import { WebLayoutDto } from "@modules/models";
import { Disposable } from "../disposable";
import { WebLayoutService } from "../services/weblayout.service";
import { TableColumn } from "../components";

/**
 * TODO: Look at removing the LAYOUT ID URL Parameter and rather write the Layout Session
 * The Table just needs to re-trigger after the application of Session Layout data
 */
export class LayoutAware extends Disposable {
  constructor(public pageName: string, private layoutService: WebLayoutService) {
    super();
  }

  layoutChanged(currentLayoutId: string, newLayoutId: string) {
    // if layoutId is different then we need to fetch the new layout
    return (currentLayoutId && !newLayoutId) || !currentLayoutId && newLayoutId || currentLayoutId !== newLayoutId;
  }

  fetchLayouts(){
    this.layoutService.getPageLayouts(this.pageName).toPromise().then(layouts => {
      this.layoutsLoaded(layouts);
    });
  }

  layouts: WebLayoutDto[] = [];
  layoutId: string = null;
  /**
   * LayoutComponent loaded Layouts for given TableId
   * will load a Layout if one was Defined on the Route
   * @param layouts
   */
  public layoutsLoaded(layouts: WebLayoutDto[]) {
    this.layouts = layouts;
    if (this.layoutId) {
      const layout = layouts.find((l) => l.layoutId === this.layoutId);
      this.setLayout(layout);
    } else {
      // no layouts wouldnt afeect the columns
      this.setLayout(null);
    }
  }

  layoutColumns: { [id: string]: TableColumn } = null;
  /**
   * Must call this with super.layoutLoaded() to trigger Layout column application and search
   * @param layout
   */
  public setLayout(layout: WebLayoutDto) {
    this.onBeforeSetLayout(layout);
    // queueMicrotask(() => {// wait for the next tick to apply the layout as this may be called at any-time after content was checked
      if(!layout){
        // check for default layout
        if(!this.applyDefaultLayout()) {
          // just clear to base defaults
          this.layoutColumns = null;
          this.triggerRefresh();
        }
        return;// clear layout was requested -- do nothing here as route navifation will trigger a search
      } else {
        this.layoutColumns = layout ? layout.data : null;
        this.triggerRefresh();// triggers search internally
      }
    // });
  }

  /**
   * Find Default Layout and apply it or return false
   */
  applyDefaultLayout() {
    if(!this.layouts.length) return;

    const sessionLayout = this.layoutService.getSessionLayout(this.pageName);
    // Layout was saved previously
    if(sessionLayout) return;

    // we need a way to check if the default layout was already applied
    const defaultLayout = this.layouts.find(l => l.caption === this.layoutService.defaultLayoutName);
    if(defaultLayout) {
      this.setLayout(defaultLayout);
      // return false;
      return true;
    } else {
      return false;
    }
  }

  triggerRefresh() {
    throw new Error("Method not implemented.");
  }

  // Override this for any custom actions before setting the layout
  onBeforeSetLayout(layout: WebLayoutDto) {
    // do nothing
  }

}

