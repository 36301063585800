import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProductSettingService } from "@services";
import { TableComponent } from "../ability-table/ability-table.component";
import { DataTableComponent } from "../data-table";

/**
 * Represents the Table Actions side of the Table Header
 */
@Component({
  selector: 'abi-table-controls',
  templateUrl: './table-controls.component.html',
  styleUrls: ['./table-controls.component.scss']
})
export class TableControlsComponent implements OnInit{
  @Input() table: TableComponent;
  @Input() dataTable: DataTableComponent;
  @Output() resetFilters?: EventEmitter<void> = new EventEmitter();
  @Input() resetButtonEnabled?: boolean = true;
  @Input() columnSelectorEnabled?: boolean = true;
  @Input() exportAllThreshold?: number = 20000;

  constructor(private productSetting: ProductSettingService) {
  }

  ngOnInit(): void {
    const pageName = this.dataTable?.pageName || this.table.id;
    this.exportAllThreshold = this.productSetting.numericValue("ExportAllThreshold_"+pageName) || this.productSetting.numericValue("ExportAllThreshold") || this.exportAllThreshold;
  }

  resetTableFilters(){
    if(this.table instanceof TableComponent || (this.table as object).hasOwnProperty('resetFilters')){
      this.table.resetFilters();// clear table session store
    }

      this.dataTable?.resetFilters();// gets new column refs and (maybe triggers default search - via cols getting changed)
    // emit on reset
    this.resetFilters.emit();// any additional resets
  }

}
