import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ForgotPasswordComponent } from "./core/forgot-password/forgot-password.component";
import { HomeComponent } from "./core/home/home.component";
import { LoginComponent } from "./core/login/login.component";
import { AuthGuard, TenantGuard } from "./modules/common/auth.guard";
import { PlaceHolderComponent } from "./core/placeholder.component";
import { ResetPasswordComponent } from "./core/reset-password/reset-password.component";
import { ResetComponent } from "./core/reset/reset.component";

// Some Routes may fail to load if the server was refreshed and the Client UI wasnt notified yet
function RouteLoadingFailure(e) {
  console.warn("Failed Lazy-Loading module - reloading page");
  // Some ROUTE LOADING failure state is usually recoverable by forcing a reload
  window.location.reload();
}

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    canActivate: [TenantGuard, AuthGuard],
    data: { title: "Company Select", narrow: true },
  },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [TenantGuard],
    data: { title: "Login", narrow: true },
  },
  {
    path: "forgotpassword",
    component: ForgotPasswordComponent,
    data: { title: "Forgot password", narrow: true },
  },
  {
    path: "resetpassword",
    component: ResetPasswordComponent,
    data: { title: "Reset password", narrow: true },
  },
  {
    path: "reset",
    component: ResetComponent,
    data: { title: "Reset", narrow: true },
  },

  {
    path: ":tenant/job",
    loadChildren: () =>
      import(
        /* webpackChunkName: 'job' */ "./modules/jobs/job.module"
      ).then(
        (mod) => mod.JobModule,
        (reason) => RouteLoadingFailure(reason)
      ),
    canActivate: [TenantGuard],
  },
  { path: ":tenant/facebook", redirectTo: "/:tenant/job/public/fbcreate" }, // TODO: route needs work once the Facebook links are sorted
  { path: ":tenant/jx/fbcreate", redirectTo: "/:tenant/job/public/fbcreate" },
  {
    path: ":tenant/jx/r/:jobId/:check",
    redirectTo: "/:tenant/job/public/rate/:jobId/:check",
  },
  {
    path: ":tenant/jx/p/:jobId/:check",
    redirectTo: "/:tenant/job/public/pay/:jobId/:check",
  },
  {
    path: ":tenant/jx/:jobId/:check",
    redirectTo: "/:tenant/job/public/:jobId/:check",
  },
  { path: ":tenant/jx/:jobId", redirectTo: "/:tenant/job/:jobId" },
  { path: ":tenant/jx", redirectTo: "/:tenant/job/public" },
  { path: ":tenant/rx", redirectTo: "/:tenant/job/public/register" },
  {
    path: ":tenant/login",
    component: LoginComponent,
    canActivate: [TenantGuard],
    data: { title: "Login", narrow: true },
  },
  {
    path: ":tenant",
    canActivate: [TenantGuard],
    children: [
      {
        path: "",
        component: HomeComponent,
        data: { title: "Home", narrow: true, isHome: true },
        canActivate: [AuthGuard],
      },
      {
        path: "menu/:id",
        component: HomeComponent,
        data: { title: "Home", narrow: true },
        canActivate: [AuthGuard],
      },
      {
        path: "reporting",
        loadChildren: () =>
          import(
            /* webpackChunkName: 'reporting' */ "./modules/reporting/reporting.module"
          ).then(
            (mod) => mod.ReportingModule,
            (reason) => RouteLoadingFailure(reason)
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "warehouse",
        loadChildren: () =>
          import(
            /* webpackChunkName: 'warehouse' */ "./modules/warehouse/warehouse.module"
          ).then(
            (mod) => mod.WarehouseModule,
            (reason) => RouteLoadingFailure(reason)
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "sales",
        loadChildren: () =>
          import(
            /* webpackChunkName: 'sales' */ "./modules/sales/sales.module"
          ).then(
            (mod) => mod.SalesModule,
            (reason) => RouteLoadingFailure(reason)
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "admin",
        loadChildren: () =>
          import(
            /* webpackChunkName: 'admin' */ "./modules/admin/admin.module"
          ).then(
            (mod) => mod.AdminModule,
            (reason) => RouteLoadingFailure(reason)
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "customer",
        loadChildren: () =>
          import(
            /* webpackChunkName: 'customer' */ "./modules/customer/customer.module"
          ).then(
            (mod) => mod.CustomerModule,
            (reason) => RouteLoadingFailure(reason)
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "reverselogistics",
        loadChildren: () =>
          import(
            /* webpackChunkName: 'reverse-logistics' */ "./modules/reverse-logistics/reverse-logistics.module"
          ).then(
            (mod) => mod.ReverseLogisticsModule,
            (reason) => RouteLoadingFailure(reason)
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "contracts",
        loadChildren: () =>
          import(
            /* webpackChunkName: 'contract' */ "./modules/contract/contract.module"
          ).then(
            (mod) => mod.ContractModule,
            (reason) => RouteLoadingFailure(reason)
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "user",
        loadChildren: () =>
          import(
            /* webpackChunkName: 'user' */ "./modules/user/user.module"
          ).then(
            (mod) => mod.UserModule,
            (reason) => RouteLoadingFailure(reason)
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "wallboard",
        loadChildren: () =>
          import(
            /* webpackChunkName: 'wallboard' */ "./modules/wallboard/wallboard.module"
          ).then(
            (m) => m.WallboardModule,
            (reason) => RouteLoadingFailure(reason)
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "public/catalog",
        loadChildren: () =>
          import(
            /* webpackChunkName: 'product-catalog' */ "./modules/product-catalog/product-catalog.module"
          ).then(
            (m) => m.ProductCatalogModule,
            (reason) => RouteLoadingFailure(reason)
          ),
      },
      { path: 'scanner', loadChildren: () => import(/* webpackChunkName: 'warehouse-scanner' */ './modules/warehouse-scanner/warehouse-scanner.module').then(m => m.WarehouseScannerModule) },
      {
        path: 'dealer',
        loadChildren: () => import(
          /* webpackChunkName: 'dealer' */ './modules/dealer/dealer.module'
        ).then(m => m.DealerModule)
      },
      {
        path: 'quality-control',
        loadChildren: () => import(
          /* webpackChunkName: 'quality-control' */ './modules/quality-control/quality-control.module'
        ).then(m => m.QualityControlModule)
      },
    ],
  },
  { path: "**", component: PlaceHolderComponent, data: { title: "404" } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
