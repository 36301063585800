import { Injectable } from "@angular/core";
import { AttachmentService, BaseAttachmentDto, TipOfTheDayDto } from "@shared/models";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import moment from "moment";
import { DataService, ServiceConfig } from "../";
import { LoginStatus } from "../app.store";
import { Progress } from "./data.service";

@Injectable({
  providedIn: "root"
})
export class TipOfTheDayService extends DataService implements AttachmentService {
  tips: BehaviorSubject<TipOfTheDayDto[]> = new BehaviorSubject([]);
  initialTip: TipOfTheDayDto = null;;

  constructor(config: ServiceConfig) {
    super(config);

    const validTenant = this.appQuery.$tenant2;
    const isLoggedIn =  this.appQuery.$loginStatus.pipe(filter(l => l === LoginStatus.True));

    // When We are 'Logged IN' there should be a valid Tenant (otherwise it's just a Basic Companies Menu listing)
    isLoggedIn.pipe(withLatestFrom(validTenant))
    .pipe(
      filter(([l, t]) => !!t),
      this.notDisposed(),
      switchMap(() => this.getTips())
    )
    .subscribe(tips => {
      this.initialTip = tips.find(t => !t.status) || tips[0];
      this.tips.next(tips);

    });
  }

  uploadAttachment(parentId: string, file: File, description: string, progress: Progress, generateCheckDigit = false, extras?: any, typeId?: string): Observable<TipOfTheDayDto> {
    const formData = new FormData();
    formData.append(description, file, file.name);
    formData.append('lastModified', moment(file.lastModified).format()); // moment's default format is ISO 8601
    return this.http.post<TipOfTheDayDto>(`values/tip`, formData, {reportProgress: true, observe: "events"}
    ).pipe(this.uploading(progress));
  }

  downloadAttachment(parentId: string, attachmentId: string): Observable<Blob> {
    return this.http.get(`values/tip/${attachmentId}`, { responseType: "blob" });
  }

  deleteAttachment(parentId: string, attachmentId: string): Observable<any> {
    return this.http.delete(`values/tip/${attachmentId}`);
  }

  downloadLink(attachment: TipOfTheDayDto, parentId?: string): string {
    if (parentId) {
      parentId += "/";
    }
    return `${parentId}values/tip/${attachment.attachmentId}`;
  }

  getTips(): Observable<TipOfTheDayDto[]> {
    return this.http.get<TipOfTheDayDto[]>("values/tip");
  }

  updateTip(tipId: string, newStatus: string): Observable<string> {
    return this.http.put(`values/tip/${tipId}/${newStatus}`, null).pipe(
      map((s: any) => {
        // map updated status back to Observed Tips
        this.tips.next(this.tips.value.map(t => t.attachmentId === tipId ? {...t, status: newStatus} : t));
        return newStatus;
      }));
  }

  fullUrl(attachment: BaseAttachmentDto, parentId?: string, extras?: any): string {
    throw new Error("Method not implemented.");
  }
}
