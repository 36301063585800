import { Injectable } from "@angular/core";
import {
  AttachmentService,
  BaseAttachmentDto,
  ConsolidatedPick,
  DeliveryNoteDto,
  generateCheckDigit,
  HistoryOrderLineDto,
  JobSummaryDto,
  ListResultDto,
  newCustomerAddress,
  newCustomerContact,
  newOrder,
  newTransferOrder,
  OrderDto,
  OrderLineDto,
  OrderLineReturnApprovalDto,
  OrderLineSearchDto,
  OrderType,
  OrderTypeDto,
  PickLineDto,
  PickListDto,
  PickListSearchDto,
  PrintedStatus,
  SearchDto,
  SearchOrderLineDto,
  TransferOrderDto
} from "@shared/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { OrderEdiDto } from "@shared/models/jobs/JobEdiDto";
import moment from "moment";
import { DataService, Progress, ServiceConfig } from "../";
import { ProductSettingService } from "./product-setting.service";

// excludes 'credits'
export type QueryOrderType = "salesorders" | "purchaseorders" | "transferorders";
// basically converts 'credits' to 'salesorders'
export const OrderTypeToQueryOrderType = (type: OrderType): QueryOrderType => {
  switch (type) {
    case "salesorders":
    case "purchaseorders":
    case "transferorders":
      return type;
    case "credits":
      return "salesorders";
  }
};

@Injectable({
  providedIn: "root"
})
export class SalesOrderService extends DataService
  implements AttachmentService {

  static typeExtraFilters = {
    purchaseorders: {},
    salesorders: { credit: false },
    transferorders: {},
    credits: { credit: true },
  };

  constructor(
    config: ServiceConfig,
    private productSetting: ProductSettingService
  ) {
    super(config);
  }
  static SALES_ORDER = 'salesorders';
  static PURCHASE_ORDER = 'purchaseorders';
  static TRANSFER_ORDER = 'transferorders';

  newLine(orderId: string, lines: OrderLineDto[]): Observable<OrderLineDto[]> {
    return this.http.post<OrderLineDto[]>(`salesorders/${orderId}`, lines);
  }

  updateLine(orderId: string, line: OrderLineDto): Observable<OrderLineDto> {
    return this.http.put<OrderLineDto>(
      `salesorders/${orderId}/${line.lineId}`,
      line
    );
  }

  newOrder(
    order: OrderDto,
    typeId: string = "salesorders"
  ): Observable<OrderDto> {
    return this.http.post<OrderDto>(typeId, order);
  }

  newTransferOrder(order: TransferOrderDto): Observable<TransferOrderDto> {
    return this.http.post<TransferOrderDto>('transferorders', order);
  }

  // @deprectated
  resubmitOrder(order: OrderDto, typeId: OrderType): Observable<OrderDto> {
    order.lines.forEach(l => l.deliveries.forEach(d => d.ediCounter = ""));
    return this.updateOrder(order, typeId);
  }

  submitOrder<T extends OrderTypeDto>(orderType: OrderType, orderId: string, ediSource: string, tableKey: string): Observable<T> {
    return this.http.put<T>(`${orderType}/${orderId}/submit/${ediSource}/${tableKey}`, {});
  }

  updateOrder(
    order: OrderDto,
    typeId: string = "salesorders",
    _generateCheckDigit = false,
    eventOriginatorId?: string
  ): Observable<OrderDto> {
    const headers = {
      ...(eventOriginatorId ? { 'Push-Event-Originator-Id': eventOriginatorId } : {})
    };
    return this.http.put<OrderDto>(
      // eslint-disable-next-line max-len
      `${typeId}${_generateCheckDigit ? '/public' : ''}/${order.orderId}${_generateCheckDigit ? '/' + generateCheckDigit(order.orderId) : ''}`,
      order,
      { headers }
    );
  }

  updateTransferOrder(
    order: TransferOrderDto,
    eventOriginatorId?: string
  ): Observable<TransferOrderDto> {
    const headers = {
      ...(eventOriginatorId ? { 'Push-Event-Originator-Id': eventOriginatorId } : {})
    };
    return this.http.put<TransferOrderDto>(
      `transferorders/${this.safeEncode(order.orderId)}`,
      order,
      { headers }
    );
  }

  // Purch/Sales Orders are basically the same Dto, TransferOrder is not compatible
  getOrder<T>(
    orderId: string,
    typeId: string = "salesorders",
    _generateCheckDigit = false
  ): Observable<T> {
    // eslint-disable-next-line max-len
    return this.http.get<T>(`${typeId}${_generateCheckDigit ? '/public' : ''}/${this.safeEncode(orderId)}${_generateCheckDigit ? '/' + generateCheckDigit(orderId) : ''}`);
  }

  deleteOrder(
    orderId: string,
    typeId: string = "salesorders"
  ): Observable<any> {
    return this.http.delete(`${typeId}/${this.safeEncode(orderId)}`);
  }

  transferOrderFactory(source: string = ""): TransferOrderDto {
    const stringValue = (id: string) =>
      this.productSetting.stringValue(id) || "";
    const tOrder = newTransferOrder();
    tOrder.orderId = "New";
    tOrder.orderDate = Date.today();
    tOrder.userCaptureId = this.appQuery.username || "WebUser";
    tOrder.statusId = stringValue("DefaultSalesOrderStatus") || "ORD";
    tOrder.typeId = stringValue("DefaultSalesOrderType");
    tOrder.sourceId =
    source ||
    stringValue("DefaultSalesOrderSource") ||
    stringValue("DefaultSource");
    return tOrder;
  }

  salesOrderFactory(source: string = ""): OrderDto {
    const stringValue = (id: string) =>
      this.productSetting.stringValue(id) || "";

    const salesOrder = newOrder();
    salesOrder.orderId = "";
    salesOrder.orderDate = Date.today();
    salesOrder.nextAction = Date.today().add(1, "day");
    salesOrder.userCaptureId = this.appQuery.username || "WebUser";
    salesOrder.statusId = stringValue("DefaultSalesOrderStatus") || "ORD";
    salesOrder.typeId = stringValue("DefaultSalesOrderType");
    salesOrder.sourceId =
      source ||
      stringValue("DefaultSalesOrderSource") ||
      stringValue("DefaultSource");

    salesOrder.contact = newCustomerContact();
    salesOrder.deliveryAddress = newCustomerAddress();

    return salesOrder;
  }

  queryPicklists(typeId: string, query: PickListSearchDto, search: SearchDto): Observable<ListResultDto<PickLineDto>> {
    return this.http.post<ListResultDto<PickLineDto>>(`${typeId}/search/picklist`, query, { params: this.searchQueryToParams(search) });
  }

  updatePickLists(typeId: OrderType, query: PickListSearchDto, loadId: string, picklistId: string): Observable<PickLineDto[]> {
    return this.http.put<any>(`${typeId}/pick/${loadId}/${picklistId}`, query);
  }

  createPickingList(typeId: OrderType, query: PickListSearchDto, loadId: string): Observable<string> {
    return this.http.post<any>(`${typeId}/pick/${loadId}`, query);
  }

  getFinePickList(typeId: OrderType, orderId: string): Observable<PickLineDto[]> {
    return this.http.get<PickLineDto[]>(`${typeId}/${orderId}/pick`);
  }

  // Get or Print Picklist
  getPickList(
    typeId: OrderType,
    mode: string,
    picklists: string = null,
    print = false
  ): Observable<PickListDto[]> {
    const url = `${typeId}/pick/${mode}/${print ? 'print' : 'preview'}`;
    if (picklists) {
      return this.http.post<PickListDto[]>(url, JSON.parse(picklists));
    }
    return this.http.get<PickListDto[]>(url);
  }

  deletePickLines(typeId: OrderType, picklists: string): Observable<any> {
    return this.http.delete(`${typeId}/pick/delete`, {
      body: JSON.parse(picklists)
    });
  }

  getPrintDeliveryNoteByPicklist(
    typeId: OrderType,
    picklistId: string,
    print = false
  ): Observable<DeliveryNoteDto> {
    return this.http.get<DeliveryNoteDto>(
      `${typeId}/picklist/${picklistId}/deliverynote/${
        print ? "print" : "preview"
      }`
    );
  }

  getPrintDeliveryNote(
    typeId: OrderType,
    orderId: string,
    deliveryNoteId: string,
    print = false
  ): Observable<DeliveryNoteDto> {
    return this.http.get<DeliveryNoteDto>(
      `${typeId}/${orderId || "_"}/${deliveryNoteId || "_"}/${
        print ? "print" : "preview"
      }`
    );
  }

  getPrintDeliveryNotes(typeId: string, printedStatus: PrintedStatus): Observable<DeliveryNoteDto[]> {
    return this.http.get<DeliveryNoteDto[]>(`${typeId}/deliverynotes/${printedStatus}`);
  }

  searchDeliveryNotes(typeId: QueryOrderType, query: SearchDto): Observable<ListResultDto<DeliveryNoteDto>>{
    return this.http.get<ListResultDto<DeliveryNoteDto>>(`${typeId}/deliverynotes`, { params: this.searchQueryToParams(query) })
  }

  getDeliveryNote(typeId: string, orderId: string): Observable<DeliveryNoteDto> {
    return this.http.get<DeliveryNoteDto>(`${typeId}/${orderId}/deliver`);
  }

  updateDeliveryNote(
    orderId: string,
    deliveryNote: DeliveryNoteDto
  ): Observable<DeliveryNoteDto> {
    return this.http.put<DeliveryNoteDto>(
      `salesorders/${orderId}/deliver`,
      deliveryNote
    );
  }

  getPickLists(typeId: OrderType, query: SearchDto): Observable<ListResultDto<ConsolidatedPick>> {
    return this.http.get<ListResultDto<ConsolidatedPick>>(`${typeId}/pick`, { params: this.searchQueryToParams(query) });
  }
  getFinePickLists(typeId: OrderType): Observable<PickLineDto[]> {
    return this.http.get<PickLineDto[]>(`${typeId}/pick/fine`);
  }

  getFineFromPickLists(typeId: OrderType, picklist: string): Observable<PickLineDto[]> {
    return this.http.get<PickLineDto[]>(`${typeId}/pick/${picklist}/fine`);
  }

  searchFinePicklist(typeId: OrderType, query: SearchDto){
    return this.http.get<ListResultDto<PickLineDto>>(`${typeId}/pick/search/fine`, { params: this.searchQueryToParams(query) });
  }

  /*PO Stuff*/

  getReceipt(typeId: OrderType, orderId: string): Observable<DeliveryNoteDto> {
    return this.http.get<DeliveryNoteDto>(`${typeId}/${orderId}/receipt`);
  }

  printReceipt(typeId: OrderType, receiptId: string, print = false): Observable<DeliveryNoteDto> {
    return this.http.post<DeliveryNoteDto>(`${typeId}/receipt/${receiptId}/${print ? "print" : "preview"}`, null);
  }

  updateReceipt(
    typeId: OrderType,
    orderId: string,
    deliveryNote: DeliveryNoteDto
  ): Observable<DeliveryNoteDto> {
    return this.http.put<DeliveryNoteDto>(
      `${typeId}/${orderId}/receipt`,
      deliveryNote
    );
  }

  getPrintReceipts(typeId: string, printedStatus: PrintedStatus): Observable<DeliveryNoteDto[]> {
    return this.http.get<DeliveryNoteDto[]>(
      `${typeId}/receipts/list/${printedStatus}`
    );
  }

  queryPrintReceipts(typeId: OrderType, query: SearchDto): Observable<ListResultDto<DeliveryNoteDto>> {
    const url = `${typeId}/receipts/search`;
    return this.http.get<ListResultDto<DeliveryNoteDto>>(url, { params: this.searchQueryToParams(query) });
  }

  getPrintReceipt(
    receiptId: string,
    receipts: string,
    print = false
  ): Observable<DeliveryNoteDto[]> {
    let url = "purchaseorders/receipts";
    if (print) {
      url = url + "/print";
    }
    if (receipts) {
      return this.http.post<DeliveryNoteDto[]>(url, JSON.parse(receipts));
    }

    return this.http
      .get<DeliveryNoteDto>(`purchaseorders/receipts/${receiptId}`)
      .pipe(map(s => [s]));
  }

  getPutawayLists(showAll: boolean): Observable<PickListDto[]> {
    return this.http.get<PickListDto[]>(`purchaseorders/pack/${showAll}`);
  }

  getPutAwayList(
    receiptId: string,
    orderId: string = null
  ): Observable<PickListDto> {
    let url = `purchaseorders/putaway/${receiptId}`;
    if (orderId) {
      url = url + "/" + orderId;
    }
    return this.http.get<PickListDto>(url);
  }

  printPutAwayList(putawayId: string): Observable<any> {
    return this.http.put<any>(`purchaseorders/putaway/${putawayId}/print`, {
      putawayId
    });
  }

  deleteDeliveryNote(deliveryNoteId: string): Observable<any> {
    return this.http.delete("salesorders/deliverynotes/" + deliveryNoteId);
  }

  getSalesOrdersByCustomer(customerId: string): Observable<OrderDto[]> {
    return this.http.get<OrderDto[]>(`salesorders/customer/${customerId}`);
  }

  queryOrders(typeId: QueryOrderType, query: SearchDto): Observable<ListResultDto<OrderDto>> {
    const url = `${typeId}/search`;
    return this.http.get<ListResultDto<OrderDto>>(url, { params: this.searchQueryToParams(query) });
  }

  queryOrderLines(
    typeId: "purchaseorders" | "salesorders",
    query: SearchDto
  ): Observable<ListResultDto<OrderLineSearchDto>> {
    const url = `${typeId}/lines/search`;
    return this.http.get<ListResultDto<OrderLineSearchDto>>(url, { params: this.searchQueryToParams(query) });
  }

  queryOrderEdis(typeId: OrderType, query: SearchDto): Observable<ListResultDto<OrderEdiDto>> {
    const url = `${typeId}/edi`;
    return this.http.get<ListResultDto<OrderEdiDto>>(url, { params: this.searchQueryToParams(query) });
  }

  uploadAttachment(
    orderId: string,
    file: File,
    description: string,
    progress: Progress,
    checkDigit = false,
    extras?: any,
    typeId?: string
  ): Observable<BaseAttachmentDto> {
    const formData = new FormData();
    formData.append(description, file, file.name);
    formData.append('lastModified', moment(file.lastModified).format()); // moment's default format is ISO 8601
    return this.http.post<BaseAttachmentDto>(
      `salesorders/${orderId}/attachment${typeId && `/${typeId}` || ''}`,
      formData,
      {
        reportProgress: true,
        observe: "events"
      }
    ).pipe(this.uploading(progress));
  }

  downloadAttachment(orderId: string, attachmentId: string): Observable<Blob> {
    return this.http.get(`salesorders/${orderId}/attachment/${attachmentId}`, {
      responseType: "blob"
    });
  }

  deleteAttachment(orderId: string, attachmentId: string): Observable<any> {
    return this.http.delete(
      `salesorders/${orderId}/attachment/${attachmentId}`
    );
  }

  downloadLink(attachment: BaseAttachmentDto, parentId?: string): string {
    if (parentId) {
      parentId += "/";
    }
    return `salesorders/${parentId}attachment/${attachment.attachmentId}`;
  }

  getApprovalSummary(
    statuses: string[],
    mode: string = "",
    filter: string = "",
    mode2?: string,
    filter2?: string,
    orderId?: string
  ) {
    let url = `salesorders/summary/approve/${statuses.join("~~")}/${
      mode
    }/${filter || ""}`;
    if (mode2 && filter) {
      url = `${url}/${mode2}/${filter2 || ""}`;
    }
    if (orderId) {
      url = url + `?orderId=${orderId}`;
    }
    return this.http.get<JobSummaryDto[]>(url);
  }

  getSummary(
    mode: string,
    filter: string = "",
    mode2?: string,
    filter2?: string
  ) {
    let url = `salesorders/summary/${mode}/${filter || ""}`;
    if (mode2 && filter) {
      url = `${url}/${mode2}/${filter2 || ""}`;
    }
    return this.http.get<JobSummaryDto[]>(url);
  }

  getHistoryLines(
    dealerId: string,
    branchId: string,
    modelId: string
  ): Observable<HistoryOrderLineDto[]> {
    return this.http.get<HistoryOrderLineDto[]>(
      `salesorders/history/${this.safeEncode(
        dealerId || "_"
      )}/${this.safeEncode(branchId || "_")}/${this.safeEncode(modelId || "_")}`
    );
  }

  updateReturnApprovals(
    updates: OrderLineReturnApprovalDto[]
  ): Observable<number> {
    return this.http
      .post("salesorders/return/confirm", updates)
      .pipe(map((u: any) => u.count));
  }

  // public api (used for roomplanner products)
  // should be a POST - and accept the EXPORT callback paylod from FP api
  sendOrderEmail(orderId: string, type: 'email' | 'quote' = 'email'): Observable<OrderDto> {
    return this.http.get<OrderDto>(`salesorders/public/${orderId}/${generateCheckDigit(orderId)}/${type}`);
  }

  // this gets passed to the roomplanner EXPORT api callback property
  getRoomplannerCallbackURL(orderId: string, type: 'email' | 'quote' = 'email'): string {
    return `${window.location.origin}/api/${this.appQuery.tenant2}/salesorders/public/${orderId}/${generateCheckDigit(orderId)}/${type}`;
  }

  getPrintLoadManifest(
    loadId: string,
    print = false
  ): Observable<any> {
    return this.http.get<any>(`loadplanning/load/${loadId}/${print ? "print" : "preview"}`);
  }

  searchOrderLines(query: SearchDto): Observable<ListResultDto<SearchOrderLineDto>> {
    return this.http.get<ListResultDto<SearchOrderLineDto>>("salesorders/lines/search", {
      params: this.searchQueryToParams(query)
    });
  }

  fullUrl(attachment: BaseAttachmentDto, parentId?: string, extras?: any): string {
    throw new Error("Method not implemented.");
  }
}
