import { AsyncPipe } from "@angular/common";
import { ChangeDetectorRef, OnDestroy, Optional, Pipe, PipeTransform } from "@angular/core";
import { CustomerMasterDto, asTextBlock, fullName, newCustomerMaster, primaryContact } from "@modules/models";
import { tap } from "rxjs/operators";
import { Subject } from "rxjs";
import { CustomerService } from "../services/customer.service";

/**
 * @deprecated does nt work nicely.... infinite requests on a production build..?
 * lookup pipes on non-cached data is not a good idea
 * When using this in a List, ensure that the list is not re-rendered on every change (use a good trackBy function)
 * - without it, the pipe will be called for every item in the list, and will make a request for each item
 */
@Pipe({
  name: 'customerlookup',
  pure: false,
})
export class CustomerLookupPipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  private currentCustomer: CustomerMasterDto;

  isBusyRequesting= false;

  constructor(private customerService: CustomerService,
    ref: ChangeDetectorRef,
    @Optional() private changeTrigger?: Subject<boolean>
  ) {
    this.asyncPipe = new AsyncPipe(ref);
  }

  // changeTrigger = new Subject<boolean>();
  setChangeTrigger(trigger: Subject<boolean>){
    this.changeTrigger = trigger;
  }

  ngOnDestroy() {
     this.asyncPipe.ngOnDestroy();
  }

  getCustomer(key: string): CustomerMasterDto {
    if(key && !this.isBusyRequesting){
      if(this.currentCustomer && this.currentCustomer.id === key) {
        // console.log('returning cached customer', this.currentCustomer);
        return this.currentCustomer;
      }
      this.isBusyRequesting = true;
      return this.asyncPipe
      .transform(
        this.customerService.getCustomer(key, true)
        .pipe(
          tap(c => {
            // console.log('customerlookup: get customer', c);
            this.currentCustomer=c;
            this.isBusyRequesting = false;
            this.changeTrigger?.next(true);
          })
        )
      );
    }
    // return this.asyncPipe.transform(of(newCustomerMaster()).pipe(tap(c => {
    //   this.currentCustomer=null;
    //   // this.isBusyRequesting = false;
    // })));
    return newCustomerMaster();
  }

  transform(key: string, format?: string, addressId?: string, withName?: boolean): any {
    switch(format){
      case 'addresslookup':
        return this.getCustomer(key)?.addresses.find(a => a.addressId === addressId)?.name || "";
      case 'address':
        const address = this.getCustomer(key)?.addresses.find(a => a.addressId === addressId);
        return asTextBlock(address, withName) || "";
      case 'primaryContact.fullName':
        return fullName(primaryContact(this.getCustomer(key)));
      case 'primaryContact.companyName':
        return primaryContact(this.getCustomer(key))?.companyName || "";
      case 'primaryContact.note':
        return primaryContact(this.getCustomer(key))?.note || "";
      case 'companyName':
      default:
        return this.getCustomer(key)?.companyName || "";
    }
  }
}
