
<ng-template #plainLabelOutput>{{ plainLabel | translate }}</ng-template>

<ng-template #exportDialog>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'Export.Title' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeDialog()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">

            <abi-form-field label="Range" cols="col-12">
                <div class="row">
                    <ng-container *ngIf="rowsObservable && totalResults <= exportAllThreshold; else exportAllDisabled">
                        <label>
                        <input name="exportRange" type="radio" value="allrows" [(ngModel)]="exportRange"> All rows
                        </label>
                    </ng-container>
                    <ng-template #exportAllDisabled>
                        <label class="text-muted">
                        <abi-tooltip
                            content=""
                            class=""
                            [tooltip]="exportTooltip"
                            placement="bottom"
                            noTranslate="true"
                        >
                            <input [disabled]="true" name="exportRange" type="radio" value="allrows" [(ngModel)]="exportRange">  All rows
                        </abi-tooltip>
                        </label>
                    </ng-template>
                </div>
                <div class="row">
                    <label class="">
                        <input name="exportRange" type="radio" value="currentpage" [(ngModel)]="exportRange"> Current page
                    </label>
                </div>
            </abi-form-field>
            <abi-form-field label="Type" cols="col-12">
                <div class="row">
                    <label class="">
                        <input name="exportType" type="radio" value="excel" [(ngModel)]="exportType"> Excel
                    </label>
                </div>
                <div class="row">
                    <label class="">
                        <input name="exportType" type="radio" value="csv" [(ngModel)]="exportType"> Comma delimited text
                    </label>
                </div>
                <div class="row">
                    <label class="">
                        <input name="exportType" type="radio" value="tsv" [(ngModel)]="exportType"> Tab delimited text
                    </label>
                </div>
                <div class="row">
                    <label class="">
                        <input name="exportType" type="radio" value="json" [(ngModel)]="exportType"> JSON
                    </label>
                </div>
                <div class="row">
                    <label class="">
                        <input name="exportType" type="radio" value="clipboard" [(ngModel)]="exportType"> Clipboard
                    </label>
                </div>
            </abi-form-field>
        </div>
    </div>
    <div class="modal-footer">
        <div class="flex-grow-1">
            <ng-container *ngIf="exportBusy">
                <div class="progress">
                    <div
                    class="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    [style.width.%]="exportProgress"
                    [attr.aria-valuenow]="exportProgress"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    ></div>
                </div>
                <span class="time-remaining">Time Remaining: {{ timeRemaining | date: 'mm:ss' }}</span>
            </ng-container>
        </div>
        <div class="buttons">

            <button type="button" class="btn btn-secondary" (click)="closeDialog()">{{ 'System.Cancel' | translate }}</button>
            <button cdkFocusInitial
                type="button" class="btn btn-primary ml-2"
                  [disabled]="exportBusy"
                  (click)="onConfirmExportClick($event)">
                  <i
                      class="mr-2"
                      [ngClass]="{
                          'far fa-file-excel fa-fw': !exportBusy,
                          'fas fa-spinner fa-fw fa-pulse': exportBusy
                      }"
                  ></i>
                {{ 'Export.Title' | translate }}</button>
        </div>
    </div>
</ng-template>

<button
    *ngIf="useMatButton; else plainButtonOutput"
    #exportButton
    mat-list-item
    [class]="buttonClass"
    (click)="onExportClick($event)"
    data-testid="exportbutton"
    [disabled]="exportBusy || disabled"
    >
    <i matListIcon class="mr-2 far fa-file-excel fa-fw" [ngClass]="iconClass"></i>
    <ng-container *ngIf="buttonLabel; else plainLabelOutput">
        <ng-container *ngTemplateOutlet="buttonLabel"></ng-container>
    </ng-container>
</button>
<ng-template #plainButtonOutput>
    <button
    #exportButton
    [class]="buttonClass"
    (click)="onExportClick($event)"
    data-testid="exportbutton"
    [disabled]="exportBusy || disabled"
    >
    <i class="mr-2 far fa-file-excel fa-fw" [ngClass]="iconClass"></i>
    <ng-container *ngIf="buttonLabel;else plainLabelOutput">
        <ng-container *ngTemplateOutlet="buttonLabel"></ng-container>
    </ng-container>
</button>
