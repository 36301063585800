import { Location } from "@angular/common";
import { Directive, HostListener, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { FieldSettings } from "@shared/models/FieldSettings";
import { catchError, filter, map, take } from "rxjs/operators";
import { Observable } from "rxjs";
import { NotificationService } from "@core/services";
import { INotifications } from "@shared/interfaces/INotifications";
import { Disposable } from "../";
import { IListenerHandle } from "../services/libraries/listener.library";
import { CUSTOM_LAYOUT_ID, WebLayoutService } from "../services/weblayout.service";

export type MessageFunction = (control: AbstractControl) => string;

@Directive()
export class BaseFormDirective extends Disposable implements OnInit, OnDestroy, INotifications {
  protected _form: FormGroup;
  public formErrors: { [control: string]: Array<string> } = {};
  public apiBusy = false;
  listeners: IListenerHandle[] = [];
  fieldSettings: FieldSettings = {};

  public validationMessages: {
    [control: string]: { [rule: string]: string | MessageFunction }
  } = {};

  get form(): FormGroup {
    return this._form;
  }

  set form(form: FormGroup) {
    this._form = form;
  }

  allErrorsObject: {
    [control: string]: { [rule: string]: any }
  };

  static childErrors(group: FormGroup, errors = {}): any {
    // eslint-disable-next-line guard-for-in
    for (const ctrl in group.controls) {
      const control = group.controls[ctrl] as AbstractControl;

      if (control instanceof FormGroup)
        errors = BaseFormDirective.childErrors(control, errors);
      else if (control instanceof FormArray)
      {
        control.controls.forEach((c, i) => {
          if (c.invalid) {
            // console.log(ctrl + i, c.errors);
            errors = { ...errors, [ctrl + i]: c.errors };
          }
          if (c instanceof FormGroup) {
            errors = BaseFormDirective.childErrors(c, errors);
          }
        });
      } else {
        if (control.invalid) {
          errors = { ...errors, [ctrl]: control.errors };
        }
      }
    }
    return errors;
  }

  static allErrors(group: FormGroup): any {
    const errors = BaseFormDirective.childErrors(group);
    return errors;
  }

  constructor(protected layoutService: WebLayoutService, protected formBuilder: FormBuilder, private alwaysShowErrors = false, protected notificationService: NotificationService = null) {
    super();
  }

  ngOnInit() {
    this.setupRoles();
    this.createForm();// should call validate the first time around
    // this.validate(false);// test if this impacts anything
    this.initKeyboardListener();
  }

  setupRoles() {
    // override role setup in sub-classes
  }

  initKeyboardListener() {
    // override keyboard listenters in sub-classes
  }

  removeKeyboardListener() {
    if(this.listeners && this.listeners.length > 0)
      this.listeners.forEach(il => il.remove());// remove keyboard listener if set
    this.listeners = [];
  }

  tenant: string;
  ngOnDestroy(): void {
    if(this.notificationService && this.tenant && this.entityType && this.entityId) {
      this.notificationService.$connected.pipe(filter(connected => connected), take(1)).subscribe(() => {
        this.notificationService.send("EntityClosed", this.tenant, this.entityType, this.entityId);
        this.notificationService.unregisterHandler(`EntityAction`);
      });
    }
    this.removeKeyboardListener();
    super.ngOnDestroy();
  }

  /**
   * Convenience function to help navigate backwards (or upwards, if no previous app navigations exist)
   * @param alternate alternate route to use when no previous app navigations exist
   */
  public back(location: Location, router: Router, route: ActivatedRoute, alternate: string, ) {
    const state: any = location.getState();
    const id: number = state.navigationId;
    if (id > 1)
      location.back();
    else
      router.navigate([alternate], { relativeTo: route });
  }

  // Uses the default form variable
  protected createForm() {
    this.form.valueChanges.pipe(this.notDisposed()).subscribe(data => this.formValueChanged(data));
    this.form.statusChanges.pipe(this.notDisposed()).subscribe(() => this.formValueChanged());
    this.formValueChanged();
  }

  protected createFormSeparate(form: FormGroup) {
    form.valueChanges.pipe(this.notDisposed()).subscribe(data => this.formValueChanged(data, form));
    form.statusChanges.pipe(this.notDisposed()).subscribe(data => this.formValueChanged(data, form));
    this.formValueChanged(undefined, form);
  }

  /**
   * Trigger current form to run attached validation routines
   * @param full flag to control is validation goes deep
   * @returns void
   */
  protected validate(full: boolean, otherForm?: FormGroup) {
    if (!this.form && !otherForm) { return; }
    const form = otherForm || this.form;

    this.validateGroup(form, "", full);
  }

  // Allow Triggering of validation from outside the form/class
  public formValueChanged(data?: any, form?: FormGroup) {
    this.validate(false, form || this.form);
  }

  protected validateArray(control: FormArray, path: string, full: boolean) {
    // eslint-disable-next-line guard-for-in
    for (let id = 0; id < control.controls.length; id++) {
      this.validateGroup(control.at(id) as FormGroup, id + "_", full);
    }
  }

  protected validateControl(control: AbstractControl, field: string) {
    const messages = this.validationMessages?.[field] || {};
    this.formErrors[field] = [];
    for (const key in control.errors) {
      if (key in control.errors) {
        const msg = messages[key] || "";
        if (typeof msg === "string") {
          this.formErrors[field].push(msg);
        } else {
          this.formErrors[field].push(msg(control));
        }
      }
    }
  }

  protected validateGroup(group: FormGroup, path: string, full: boolean) {
    for (const field in group.controls) {
      if (!(field in group.controls)) {
        continue;
      }
      const control = group.controls[field];
      if (control && (control.dirty || full || this.alwaysShowErrors) && !control.valid) {

        // Experimental: Mark as dirty if full validation is requested (maybe rather mark as touched?)
        if (full && !control.touched)
          control.markAsTouched();

        this.formErrors[path + field] = [];
        const messages = this.validationMessages?.[field] || {};
        for (const key in control.errors) {
          if (key in control.errors) {
            const msg = messages[key] || "";
            if (typeof msg === "string") {
              this.formErrors[path + field].push(msg);
            } else {
              this.formErrors[path + field].push(msg(control));
            }
          }
        }
      } else if (control && control.valid) {
        this.formErrors[path + field] = [];
      }
      if (control instanceof FormGroup) {
        this.validateGroup(control, "", full);
      }
      if (control instanceof FormArray) {
        this.validateControl(control, field);// validate FormArray itself
        this.validateArray(control, path, full);// validate it's children
      }
    }
  }

  public groupErrors(group: FormGroup) {
    const result = {};
    for (const field in group.controls) {
      if (!(field in group.controls)) {
        continue;
      }
      const control = group.controls[field];

      if (control instanceof FormGroup) {
        const sub = this.groupErrors(control);
        if (!!Object.getOwnPropertyNames(sub).length) {
          result[field] = sub;
        }
      } else if (control instanceof FormArray) {
        const arrResult = [];
        // eslint-disable-next-line guard-for-in
        for (const ctrl of control.controls) {
          const sub = this.groupErrors(ctrl as FormGroup);
          if (!!Object.getOwnPropertyNames(sub).length) {
            arrResult.push(sub);
          }
        }
        if (arrResult.length) {
          result[field] = arrResult;
        }
      } else if (control && control.invalid) {
        result[field] = control.errors;
      }

    }
    return result;
  }

  clearErrors() {
    this.formErrors = {};
  }

  getPayload() {
    return this.form.getRawValue();
  }

  _layoutName: string = "BaseFormDirective";
  set layoutName(name: string) {
    this._layoutName = name;
  }
  get layoutName(): string {
    return this._layoutName;
  }

  /**
   * Field Settings Wish List:
   * we want to have Core System Default Settings, and then Tenant Specific Overrides (similar to Translations)
   * we want to have a 'dev mode' were we can interactively change the settings and see the results
   * 1. Field Visibility
   * 2. Field Required
   * 3. Field Disabled
   * 4. Field Validation
   * 5. Field Options
   * 6. Field Type
   * 7. Field Label
   * 8. Field Help Text
   * 9. Field Placeholder
   * @returns Observable<FieldSettings>
   * @see BaseModal.getFieldSettings
   */
  getFieldSettings(): Observable<FieldSettings> {
    return this.layoutService.getPageLayouts(this.layoutName)
    .pipe(
      catchError(e => {
        console.warn(`${this.layoutName}: invalid request`, e);
        return [];
      }),
      map(layouts => {
        if (layouts.length) {
          const webLayout = layouts.find(item => item.layoutId === CUSTOM_LAYOUT_ID);
          this.fieldSettings = webLayout?.data;
          return this.fieldSettings;
        }
        return {};
      }
    ));
  }

  /**
   * Static Version of getFieldSettings
   */
  static getFieldSettings(layoutService: WebLayoutService, layoutName: string): Observable<FieldSettings> {
    return layoutService.getPageLayouts(layoutName)
    .pipe(
      catchError(e => {
        console.warn(`${layoutName}: invalid request`, e);
        return [];
      }),
      map(layouts => {
        if (layouts.length) {
          const webLayout = layouts.find(item => item.layoutId === CUSTOM_LAYOUT_ID);
          return webLayout?.data;
        }
        return {};
      }
    ));
  }

  showField(field: string, defaultVisible: boolean = true) {
    if (this.fieldSettings?.[field]?.disabled === undefined) return defaultVisible; // default
    return this.fieldSettings?.[field]?.disabled === false ? true : false;
  }


  serverDataUpdated = false;
  onUpdateNotificationReceived(data: any) {
    this.serverDataUpdated = true;
  }

  onDataReloaded() {
    this.serverDataUpdated = false;
  }

  saveButtonsVisible(): boolean {
    return this.form?.dirty;
  }

  saveAllowed(): boolean {
    return this.saveButtonsVisible() && this.form.enabled && this.form.valid && !this.apiBusy && !this.serverDataUpdated;
  }

  saveIfPossible() {
    if(this.saveAllowed())
    {
      this.saveChanges();
    }
  }

  saveChanges(e?: any): any {
    throw new Error("Not implemented");
  }

  cancelAllowed(): boolean {
    return this.saveButtonsVisible() && this.form.enabled;
  }

  cancelIfPossble() {
    if(this.cancelAllowed())
    {
      this.cancelChanges();
    }
  }

  cancelChanges() {
    throw new Error("Not implemented");
    // this.form.reset();
  }

  toArray(data: FormArray, header: HTMLTableRowElement, skipFirst = false): any[][] {
    const hdrs = Array.from(header.cells).map(c => c.innerText); // .splice(-1, 1);
    const rows =  data.getRawValue() as any[];
    const result = rows.map(r => {
      const res = Object.values(r);
      if (skipFirst)
        res.splice(0, 1);
      return res;
    });
    result.splice(0, 0, hdrs);
    return result;
  }

  entityType: string = 'job';
  entityId: string;
  initNotificationListener(tenant: string, type: string, id: string){
    if(this.notificationService && tenant && type && id) {
      // Only Send if connection was established
      this.notificationService.$connected.pipe(filter(connected => connected), take(1)).subscribe(() => {
        this.notificationService.send("EntityOpenend", tenant, type, id)
        .then(() => {
          // prevents the message from being received as it's sent
          setTimeout(() => {
            this.notificationService.registerHandler('EntityAction', (iTenant, iType, iId, data) => {
              this.notifyEntityAction('EntityAction', iTenant, iType, iId, data);
              // this.snackBar.info(`${iTenant} ${iType} ${iId} ${data}`);
            });
          }, 1);
        })
        .catch(err => console.error(err));
      });
    } else {
      console.warn("Cannot Initialize Notification Listener - missing parameters", tenant, type, id);
    }
  }

  notifyEntityAction(action: string, tenant: string, type: string, id: string, data: any) {
    throw new Error("Not implemented");
  }

  /**
   * Get Unique Connection ID for the current user/connection
   * When the API's are given this ID, they can differentiate between different users/connections and route messages accordingly
   */
  get connectionId(): string {
    return this.notificationService.connection.connectionId || "";
  }

  // All pase events in a Form component to be handled by this function
  @HostListener("paste", ["$event"]) hostPaste(event: ClipboardEvent) {
    this.pasteEventListener(event);
  }

  pasteEventListener(event: ClipboardEvent) {
    // override paste listener in sub-classes
    event.preventDefault();
    let paste = (event.clipboardData || window['clipboardData']).getData("text");
    // do something with the paste
    const eventTarget = event.target as HTMLInputElement;
    switch(eventTarget.type) {
      case "text":
      case "textarea":
        // leave the line feed/return characters (stripped automatically by the text input)
        paste = paste.replace(/[\t\0]/g, "").trim();
        break;
      default:
        break;
    }
    this.insertAtCursor(eventTarget, paste);
    // Ensure Input event is fired on the Element so that Angular Reactive Forms pick up the change
    eventTarget.dispatchEvent(new Event('input', { bubbles: true }));
    event.stopPropagation();
  }

  // http://stackoverflow.com/questions/11076975/insert-text-into-textarea-at-cursor-position-javascript
  private insertAtCursor(myField: HTMLInputElement, myValue: string) {
    //IE support
    if (document['selection']) {
        myField.focus();
        const sel = document['selection'].createRange();
        sel.text = myValue;
    }
    //MOZILLA and others
    else if (myField.selectionStart || myField.selectionStart === 0) {
        const startPos = myField.selectionStart;
        const endPos = myField.selectionEnd;
        myField.value = myField.value.substring(0, startPos)
            + myValue
            + myField.value.substring(endPos, myField.value.length);
        myField.selectionStart = startPos + myValue.length;
        myField.selectionEnd = startPos + myValue.length;
    } else {
        myField.value += myValue;
    }
  }

  async executeSequentially(promiseFactories: (() => Promise<any>)[] = []) {
    const results = [];
    for (const pf of promiseFactories) {
      results[results.length] = await pf();
    }
    return results;
  }
}
