<ng-container *ngIf="inModal; else inputSection">
    <h4 mat-dialog-title>{{ group.value.modelId ? ('System.Edit' | translate) : ('System.New' | translate) }} {{ 'Job.Machine' | translate }}</h4>
    <mat-dialog-content>
        <ng-container *ngTemplateOutlet="inputSection"></ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button cdkFocusInitial type="button" mat-button mat-dialog-close [disabled]="group.disabled || group.invalid" (click)="accept()">
        {{ 'System.Ok' | translate }}</button>
        <button type="button" mat-button mat-dialog-close (click)="cancel()">
        {{ 'System.Cancel' | translate }}</button>
    </mat-dialog-actions>
</ng-container>

<ng-template #inputSection>
<div [formGroup]="group">
    <abi-machine-categories [categoryControls]="categoryControls" [horizontal]="horizontal" class="categories-wrapper card-group" [lookupDisplay]="getLookupDisplay()" [allCategories]="mustShowAllCategories()">
        <h5 role="model-header" class="card-title">
        Model Info <button class="btn btn-sm btn-primary float-right" (click)="unlockMachine()" *ngIf="!modelUnlocked && showUnlock"><i class="fas" [ngClass]="{'fa-lock': modelUnlocked, 'fa-lock-open': !modelUnlocked  }"></i> Update Model</button>
        </h5>
        <!-- {{ selectedCategories | json }} -->
        <!-- {{ filterModelsBy | json }} -->
        <div role="model-info" class="model-info row">
            <!-- <div class="row"> -->
                <abi-form-field *ngIf="showField('modelId')" label="Machine.ModelID" [errors]="formErrors.modelId" class="machine-modelid" cols="col-12">
                    <div class="input-group">
                        <input
                            formControlName="modelId"
                            abiTypeahead
                            [abiModel]="selectedCategories"
                            [extraFilters]="filterModelsBy"
                            (selectItem)="modelSelected($event.item)"
                            container="body"
                            [codeOnly]="true"
                        />
                        <div *ngIf="showButton || canClearModel" class="input-group-append">
                            <button *ngIf="showButton" class="btn btn-outline-primary px-1" type="button" (click)="addModel()" data-testid="add-model">
                                <i class="fas fa-plus"></i>
                            </button>
                            <button *ngIf="canClearModel" class="btn btn-outline-primary px-1" type="button" (click)="clearModel()" data-testid="clear-model">
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                </abi-form-field>
                <abi-form-field label="Machine.MachineName" class="machine-machinename" cols="col-12">
                    <input formControlName="name" id="machineName" maxlength="64" />
                </abi-form-field>
        <!-- </div> -->
        </div>
        <div role="model-details">
            <div class="row">
                <abi-form-field *ngIf="showField('serialNumber')" label="Machine.Serial" class="machine-serial" tooltip="Machine.SerialTooltip" cols="col-12" [errors]="formErrors.serialNumber">
                    <abi-scan
                        [enabled]="group.enabled"
                        [count]="2"
                        [checkModel]="true"
                        (scanned)="scanned($event)"
                        [modelId]="group.value.modelId"
                        [serialNumber]="group.value.serialNumber"
                        [scanOnly]="fieldSettings?.serialNumber?.readonly">
                        <input formControlName="serialNumber" id="serialNumber"/>
                    </abi-scan>
                </abi-form-field>

                <abi-form-field *ngIf="showField('dateOfPurchase')" label="Machine.DateOfPurchase" class="machine-dateofpurchase" cols="col-12" [errors]="formErrors.dateOfPurchase">
                    <abi-dateselector formControlName="dateOfPurchase" [disableInput]="false" [convertOnChange]="false"></abi-dateselector>
                </abi-form-field>

                <abi-form-field *ngIf="showField('field1')" label="Machine.Field1" class="machine-field1" cols="col-12" [errors]="formErrors.field1" forId="machinefield1">
                    <ng-template [ngTemplateOutlet]="inputField" [ngTemplateOutletContext]="getFieldContext('field1')"></ng-template>
                </abi-form-field>

                <abi-form-field *ngIf="showField('field2')" label="Machine.Field2" class="machine-field2" cols="col-12" [errors]="formErrors.field2" forId="machinefield2">
                    <ng-template [ngTemplateOutlet]="inputField" [ngTemplateOutletContext]="getFieldContext('field2')"></ng-template>
                </abi-form-field>

                <abi-form-field *ngIf="showField('field5')" label="Machine.Field5" class="machine-field5" cols="col-12" [errors]="formErrors.field5" forId="machinefield5">
                    <ng-template [ngTemplateOutlet]="inputField" [ngTemplateOutletContext]="getFieldContext('field5', 'checkbox')"></ng-template>
                </abi-form-field>

                <abi-form-field *ngIf="showField('dealerBranchId')" label="Machine.BranchID" class="machine-branchid" cols="col-12" [errors]="formErrors.dealerBranchId">
                    <input id="dealerBranchId" formControlName="dealerBranchId" [inputDisplay]="getLookupDisplay()" [dropDownDisplay]="getLookupDisplay()" abiTypeahead abiDealer container="body" [showAllBranches]="true"/>
                </abi-form-field>

                <abi-form-field
                    *ngIf="showField('attachment', false)"
                    class="machine-attachment"
                    label="Machine.Attachment"
                    [errors]="formErrors.fileUpload"
                    wrap="true"
                    cols="col-12"
                >
                    <div class="input-group">
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" id="inputGroupFile01" (change)="attachmentChanged($event)" noBootstrap />
                            <label #attachmentsLabel class="custom-file-label" for="inputGroupFile01">Choose file</label>
                        </div>
                    </div>
                </abi-form-field>

                <abi-form-field *ngIf="showField('field3')" label="Machine.Field3" class="machine-field3" cols="col-12" [errors]="formErrors.field3" forId="machinefield3">
                    <ng-template [ngTemplateOutlet]="inputField" [ngTemplateOutletContext]="getFieldContext('field3')"></ng-template>
                </abi-form-field>

                <abi-form-field *ngIf="showField('field4')" label="Machine.Field4" class="machine-field4" cols="col-12" [errors]="formErrors.field4" forId="machinefield4">
                    <ng-template [ngTemplateOutlet]="inputField" [ngTemplateOutletContext]="getFieldContext('field4')"></ng-template>
                </abi-form-field>
            </div>
        </div>
    </abi-machine-categories>
    <ng-content></ng-content>
</div>
</ng-template>

<ng-template #inputField let-type="type" let-name="name" let-id="id" let-lookup="lookup" let-readonly="readonly">
    <ng-container [formGroup]="group">
        <ng-container *ngIf="type==='text'">
            <input type="text" [formControlName]="name" [id]="id" [readonly]="readonly"/>
        </ng-container>
        <ng-container *ngIf="type==='checkbox'">
            <div class="row">
                <div class="custom-control custom-checkbox input-group">
                    <input class="custom-control-input" type="checkbox" [formControlName]="name" value="yes" [readonly]="readonly"/>
                    <label class="custom-control-label" [for]="name">&nbsp;</label>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="type==='lookup'">
            <input [formControlName]="name" abiTypeahead [abiLookup]="lookup" [id]="id" [codeOnly]="true" [disabled]="readonly"/>
        </ng-container>
    </ng-container>
</ng-template>
