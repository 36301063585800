import { Directive, Input } from "@angular/core";
import { CustomerMasterDto, SearchDto } from "@shared/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CustomerService } from "../services/index";
import { BaseObservableTypeaheadHelper } from "./base-typeahead-helper";
import { TypeaheadDirective } from "./typeahead.directive";

@Directive({
  selector: "input[abiTypeahead][abiSupplier]"
})
export class SupplierTypeaheadDirective extends BaseObservableTypeaheadHelper<CustomerMasterDto> {

  constructor(typeAhead: TypeaheadDirective, private customerService: CustomerService) {
    super(typeAhead, []); // , ["abiCustomer"]
    this.loading = false;
  }

  protected getId(item: CustomerMasterDto): string {
    return item.id;
  }
  protected getName(item: CustomerMasterDto): string {
    return item.name;
  }

  protected filteredList(term: string): Observable<CustomerMasterDto[]> {
    const search: SearchDto = {
      filters: { name: term}, // categoryId: this.abiCustomer || "CUS"
      orderBy: ["name"],
      pageNumber: 1,
      pageSize: 10,
    };
    return this.customerService.querySuppliers(search).pipe(map(r => r.items));
  }
}
