import { Component, ViewChild } from "@angular/core";
import { environment } from "@env/environment";
import { AppQuery } from "@modules/common/app.store";
import { TipOfTheDayService } from "@modules/common/services";
import { NgbCarousel } from "@ng-bootstrap/ng-bootstrap";
import { NgbSlideEvent } from "@ng-bootstrap/ng-bootstrap/carousel/carousel";
import { TipOfTheDayDto } from "@shared/models";
import { filter } from "rxjs/operators";

@Component({
  selector: "abi-tip-of-the-day",
  templateUrl: "./tip-of-the-day.component.html",
  styleUrls: ["./tip-of-the-day.component.scss"]
})
export class TipOfTheDayComponent {
  @ViewChild("carousel") carousel: NgbCarousel;

  constructor(private tipOfTheDayService: TipOfTheDayService, private appQuery: AppQuery) {

    this.tipOfTheDayService.tips.pipe(filter(tips => tips.length > 0)).subscribe(tips => {
      this.currentAttachmentId = this.tipOfTheDayService.initialTip.attachmentId;
    });
  }

  get tips(): TipOfTheDayDto[] {
    return this.tipOfTheDayService.tips.value.filter(t => t.status !== "Hidden");
  }

  currentAttachmentId = "";
  get tip(): TipOfTheDayDto {
    return this.tips.find(t => t.attachmentId === this.currentAttachmentId);
  }

  downloadLink(attachment: TipOfTheDayDto): string {
    return this.tipOfTheDayService.downloadLink(attachment, `${environment.webApi}/api/${this.appQuery.tenant2}`);
  }

  slide(ev: NgbSlideEvent) {
    this.tipOfTheDayService.updateTip(this.tip.attachmentId, "Read").toPromise().then(_ => { });
    this.currentAttachmentId = ev.current;
  }

  close() {
    this.tipOfTheDayService.updateTip(this.tip.attachmentId, "Read").toPromise().then(_ => { });
  }
}
