import { Injectable } from "@angular/core";
import { Query, Store, StoreConfig } from "@datorama/akita";
import { environment } from "@env/environment";
import { FullMenuDto, UserCompanyDto, WebMenuDto } from "@shared/models";
import { combineLatest, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ServerEnvironment } from "src/util";

export enum LoginStatus {
  _,
  True,
  False,
}
export interface AppState {
  tenant: string;
  tenant2: string;
  username: string;
  roles: string[];
  claims: { [id: string]: number };
  companies: UserCompanyDto[];
  warehouses: string[];
  loginStatus: LoginStatus;
  wideScreen: boolean;
  homescreenLinks: boolean;
  primaryRole: string;
  defaultResource: string;
  skipResource: boolean;
  warehouse: string;
  warehouse2: string;
  pageSize: number;
  pinSidenav: "over" | "side";
  hideRails: boolean;
  menu: WebMenuDto[];
  fullmenu: FullMenuDto[];
  language: string;
  serverEnvironment: string;
  useSidebarMenu: boolean;
  metaParams?: string;
  baseURL: string;
}

export function createInitialState(defaults?: Partial<AppState>): AppState {
  return {
    tenant: "",
    tenant2: "",
    username: "",
    roles: [],
    companies: [],
    warehouses: [],
    claims: {},
    loginStatus: LoginStatus.False,
    wideScreen: true,
    homescreenLinks: true,
    primaryRole: "",
    defaultResource: "",
    skipResource: true,
    warehouse: "",
    warehouse2: "",
    pageSize: null,// User's page size option
    pinSidenav: "over",
    hideRails: false,
    menu: [],
    fullmenu: [],
    language: "en",
    serverEnvironment: ServerEnvironment.DEV,
    useSidebarMenu: false,
    baseURL: defaults?.baseURL || "",// Used for displaying attachment Images
  };
}

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "app" })
export class AppStore extends Store<AppState> {
  constructor() {
    super(createInitialState({ baseURL: environment.webApi }));
  }
}

@Injectable({ providedIn: "root" })
export class AppQuery extends Query<AppState> {
  $tenant = this.select((s) => s.tenant);
  $tenant2 = this.select((s) => s.tenant2);
  $companies = this.select((s) => s.companies);
  $loginStatus = this.select((s) => s.loginStatus);
  $username = this.select((s) => s.username);
  $roles = this.select((s) => s.roles);
  $warehouses = this.select((s) => s.warehouses);
  $wideScreen = this.select((s) => s.wideScreen);
  $homescreenLinks = this.select((s) => s.homescreenLinks);
  $primaryRole = this.select((s) => s.primaryRole);
  $defaultResource = this.select((s) => s.defaultResource);
  $pinSidenav = this.select((s) => s.pinSidenav);
  $hideRails = this.select((s) => s.hideRails);
  $skipResource = this.select((s) => s.skipResource);
  claims$ = this.select((s) => s.claims);
  menu$ = this.select((s) => s.menu);
  fullmenu$ = this.select((s) => s.fullmenu);
  language$ = this.select((s) => s.language);
  warehouse$ = this.select((s) => s.warehouse);
  warehouse2$ = this.select((s) => s.warehouse2);
  pageSize$ = this.select((s) => s.pageSize);
  serverEnvironment$ = this.select((s) => s.serverEnvironment);
  useSidebarMenu$ = this.select((s) => s.useSidebarMenu);

  get tenant() {
    return this.getValue().tenant;
  }
  get tenant2() {
    return this.getValue().tenant2;
  }
  get companies() {
    return this.getValue().companies;
  }
  get loginStatus() {
    return this.getValue().loginStatus;
  }
  get username() {
    return this.getValue().username;
  }
  get roles() {
    return this.getValue().roles;
  }
  get warehouses() {
    return this.getValue().warehouses;
  }
  get wideScreen() {
    return this.getValue().wideScreen;
  }
  get homescreenLinks() {
    return this.getValue().homescreenLinks;
  }
  get primaryRole() {
    return this.getValue().primaryRole;
  }
  get defaultResource() {
    return this.getValue().defaultResource;
  }
  get warehouse() {
    return this.getValue().warehouse;
  }
  get warehouse2() {
    return this.getValue().warehouse2;
  }
  get pageSize() {
    return this.getValue().pageSize;
  }
  get pinSidenav() {
    return this.getValue().pinSidenav;
  }
  get hideRails() {
    return this.getValue().hideRails;
  }
  get skipResource() {
    return this.getValue().skipResource;
  }
  get claims() {
    return this.getValue().claims;
  }
  get menu() {
    return this.getValue().menu;
  }
  get fullmenu() {
    return this.getValue().fullmenu;
  }
  get language() {
    return this.getValue().language;
  }
  get serverEnvironment() {
    return this.getValue().serverEnvironment;
  }
  get useSidebarMenu() {
    return this.getValue().useSidebarMenu;
  }
  get baseURL() {
    return this.getValue().baseURL;
  }

  constructor(protected store: AppStore) {
    super(store);
  }

  hasClaim(claim: string | string[]): boolean {
    if (!Object.keys(this.claims).length) return true;
    if (typeof claim === "string") return this.claims[claim] !== -1;
    let result = true;
    for (const cl of claim)
      result = result && this.claims[cl] !== -1;
    return result;
  }

  hasRole(role: string, sysadmin: boolean = true): boolean {
    const roles = this.roles;
    return roles.includes(role) || (sysadmin && roles.includes("SYSADMIN"));
  }

  hasRoles(roles: string[], sysadmin: boolean = true): boolean {
    const uRoles = this.roles;
    return (
      roles.includes("*") ||
      uRoles.some((r) => roles?.includes(r)) ||
      (sysadmin && uRoles.includes("SYSADMIN"))
    );
  }

  /**
   * Not technically correct as the normal 'demo' tenant does not have a '-' in it...
   * @returns boolean
   */
  isDemoTenant(): Observable<boolean> {
    return this.$tenant2.pipe(map((t) => t.indexOf("-") !== -1));
  }

  isProductionServer(): boolean {
    return this.serverEnvironment === ServerEnvironment.PRODUCTION;
  }

  /**
   * is [wallboard]Demo Server: and Demo tenant
   * @returns Observale<boolean>
   */
  isDemoDemo(): Observable<boolean> {
    return combineLatest([
      this.$tenant2.pipe(map((t) => t === "demo")),
      of(
        this.serverEnvironment === ServerEnvironment.DEMO ||
          this.serverEnvironment === ServerEnvironment.WALLBOARD_DEMO
      ),
    ]).pipe(
      map(([isDemoTenant, isDemoServer]) => {
        return isDemoTenant && isDemoServer;
      })
    );
  }

  shouldShowInfoHeader(): Observable<boolean> {
    return combineLatest([
      this.isDemoDemo(),
      of(this.isProductionServer()),
      this.isDemoTenant(),
    ]).pipe(
      map(([isDemoDemo, isProductionServer, isDemoTenant]) => {
        if (isDemoDemo) {
          return false;
        } else {
          return isDemoTenant || !isProductionServer;
        }
      })
    );
  }
}
