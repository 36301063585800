import { deepFind } from "./object.utilities";

export const NON_DIGIT_AND_PLUS = /[^+\d]+/g;// Matches Non-Digits and Non-Plus
export const NON_WORD = /\W+/g;// Matches Non-Word Characters
export const NOT_ALPHA_NUMERIC_PLUS = /[^a-zA-Z0-9+]+/g;// Matches Alpha Numeric Characters
export const PHONE_MASK = "(000)000-0000";
export const PHONE_REGEX = /\(?[0-9]{3}\)?[0-9]{3}-?[0-9]{4}/;
export const ID_NUMBER_REGEX = /[0-9]{13}/;
export const ID_NUMBER_MASK = "0000000000000";
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;
export const POSTAL_CODE_REGEX = /^[0-9]{4}$/;
export const POSTAL_CODE_MASK = "0000";
export const CURRENCY_REGEX = /^\d+(\.\d{1,2})?$/;
export const CURRENCY_MASK = "separator.2";
export const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;
export const DATE_MASK = "0000-00-00";
export const TIME_REGEX = /^\d{2}:\d{2}$/;
export const TIME_MASK = "00:00";
export const DATE_TIME_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
export const DATE_TIME_MASK = "0000-00-00T00:00";
export const DATE_TIME_SECONDS_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
export const DATE_TIME_SECONDS_MASK = "0000-00-00T00:00:00";
export const DATE_TIME_MILLISECONDS_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}$/;
export const DATE_TIME_MILLISECONDS_MASK = "0000-00-00T00:00:00.000";

export const DATE_TIME_SECONDS_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm";
export const DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "HH:mm";
export const DATE_TIME_SECONDS_FORMAT_LONG = "YYYY-MM-DDTHH:mm:ss.SSS";
export const DATE_TIME_SECONDS_FORMAT_SHORT = "YYYY-MM-DDTHH:mm:ss";
export const DATE_TIME_FORMAT_LONG = "YYYY-MM-DDTHH:mm";
export const DATE_FORMAT_LONG = "YYYY-MM-DD";
export const TIME_FORMAT_LONG = "HH:mm";
export const CODE_VALUE_REGEX = /([A-Z0-9-]{3,64})/;
export const REFERENCE_PATTERN = "[a-zA-Z0-9-_]*";
export const REFERENCE_REGEX = new RegExp(REFERENCE_PATTERN);

// Add Functions to the String Class/Prototype
declare global {
  interface StringConstructor {
    newlineReplace(val: string): string;
  }
  interface String {
    isOneOf(values: string[]): boolean;
    newlineReplace(): string;
  }
}


String.newlineReplace = function(str: string){
  return str.replace(/\\[n|r]/g, "\n");
};

String.prototype.newlineReplace = function(): string {
    return this?.replace(/\\[n|r]/g, "\n");
};

String.prototype.isOneOf = function(values: string[]): boolean {
  return values.includes(this);
};

export function toProperCase(value: string): string {
  return value.toLowerCase().replace(/^(.)|\s(.)/g, val => val.toUpperCase());
}



export function getCurlyBraceContent(value: string): string[] {
  if(!value) return null;
  const paramsPattern = /[^{\}]+(?=})/g;
  return value.match(paramsPattern);
}

/**
 * Given: '{some} and {other}' with params: {some:'s1', other:'val'} will generate: "s1 and val"
 * Or Given: '{some.field} and {some.otherField}' with params: {some: { field: 's1', otherField:'val'}} will generate: "s1 and val"
 */
export function replaceStringTemplate(templateString: string, params: any): string
{
  const paramsPattern = /[^{}]+(?=})/g;
  const extractParams = templateString.match(paramsPattern);
  // console.log("extractParams", extractParams)
  let outputString = templateString;
  extractParams.forEach(par => {
    outputString = outputString.replace(`{${par}}`, dotPathValueGetter(params, par));
  });
  return outputString;
}

/**
 * Originally used by the Table component to reference deep object properties
 */
export function dotPathValueGetter(value: any, path: string): any {
  let cValue = "";
      // Standard Value Getter code path
      // Use dot Pathing to reference complex Object props
      cValue = path.split(".")
      .reduce((prev, curr) => {
        if (curr.endsWith(")")) {
          const elems = curr.replace(")", "").split("(");
          curr = elems[0];
          const pars = elems[1].split(",") || undefined;
          return prev ? prev[curr].apply(prev, pars) : undefined; // pars
        } else {
          return prev ? prev[curr] : undefined;
        }
      }, value || self);
    return cValue;
}


/**
 * Path may contain a complex or simple template string, e.g. '{contact.title} {contact.firstName} {contact.lastName}' or 'someField'
 */
export function getValueFromPath(obj: any, path: string) {
    if (path) {
      if(path.includes('{') && path.includes('}')){
        return replaceStringTemplate(path, obj);
      }
      const formattedValue = deepFind(obj, path);
      if (formattedValue)
        return formattedValue;
    }
    return "";
  }

export function newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
      // eslint-disable-next-line no-bitwise
      const r = Math.random() * 16 | 0;
      // eslint-disable-next-line no-bitwise
      const v = c === "x" ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
