<div class="modal-body">
    <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">
            <i class="fas" [ngClass]="{'fa-search': !searching, 'fa-spinner fa-pulse': searching}"></i>
          </span>
        </div>
        <input
            type="text"
            class="form-control search-bar"
            [ngClass]="{ 'searching': searching }"
            placeholder="Search All..."
            [(ngModel)]="searchTerm"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="modelChangeTrigger($event)"
            ngbAutofocus
        />
    </div>

    <div class="content mt-2" *ngIf="items?.length">
        <div class="row">
            <div class="col-12">
                <div class="list-group">
                    <!-- show groupings -->
                    <!-- <ng-container
                        *ngFor="let item of items">
                    </ng-container> -->
                    <button
                        *ngFor="let item of items"
                        type="button"
                        class="list-group-item list-group-item-action"
                        (click)="selectItem(item)"
                    >
                        <div class="d-flex justify-content-between">
                            <div class="result-left">
                                <i [class]="item.icon"></i>
                                <!-- <span class="ml-2">{{ item.id }} - {{ item.name }}</span> -->
                                <span class="ml-2">
                                    <ngb-highlight [result]="resultItemFormatter(item)" [term]="searchTerm"></ngb-highlight>
                                </span>
                            </div>
                            <div class="result-right">
                                <small>{{ item.entity }}</small>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
