import { animate, animateChild, AnimationTriggerMetadata, group, query, state, style, transition, trigger } from '@angular/animations';

const defaultDuration = "130ms";
const defaultMinWidth = "60px";
const defaultMaxWidth = "300px";
const defaultMinFontSize = "24px";
const defaultMaxFontSize = "24px";

export function mainContentAnimation(
  animationDuration: string = defaultDuration,
  minWidth: string = defaultMinWidth,
  maxWidth: string = defaultMaxWidth,
): AnimationTriggerMetadata {
  return trigger("onSideNavChange", [
    state("close",
      style({
        "margin-left": minWidth,
      })
    ),
    state("open",
      style({
        "margin-left": maxWidth,
      })
    ),
    transition("close => open", animate(`${animationDuration} ease-in`)),
    transition("open => close", animate(`${animationDuration} ease-out`)),
  ]);
}

export function sidebarAnimation(
  animationDuration: string = defaultDuration,
  minWidth: string = defaultMinWidth,
  maxWidth: string = defaultMaxWidth,
): AnimationTriggerMetadata {
  return trigger("onSideNavChange", [
    state("close",
      style({
        width: minWidth,
        opacity: 0.6
      })
    ),
    state("open",
      style({
        width: maxWidth,
        opacity: 1
      })
    ),
    state("open-mobile",
      style({
        width: maxWidth,
        opacity: 1
      })
    ),
    state("open-close",
      style({
        width: maxWidth,
        opacity: 1
      })
    ),
    transition("open-mobile => mobile-close", group([
      animate(`1ms linear`)
    ])),
    transition("mobile-close => open-mobile", group([
      animate(`1ms linear`)
    ])),
    transition("close => open", group([
      query("@iconAnimation", animateChild()),
      query("@labelAnimation", animateChild()),
      animate(`${animationDuration} ease-in-out`)
    ])),
    transition("open => close", group([
      query("@iconAnimation", animateChild()),
      query("@labelAnimation", animateChild()),
      animate(`${animationDuration} ease-in-out`)
    ])),
  ]);
}

export function iconAnimation(
  animationDuration: string = defaultDuration,
  minFontSize: string = defaultMinFontSize,
  maxFontSize: string = defaultMaxFontSize,
): AnimationTriggerMetadata {
  return trigger("iconAnimation", [
    state("open", style({
      // fontSize: maxFontSize,
    })),
    state("close", style({
      // fontSize: minFontSize,
    })),
    transition("close => open", animate(`${animationDuration} ease-in`)),
    transition("open => close", animate(`${animationDuration} ease-out`)),
  ]);
}

export function labelAnimation(
  animationDuration: string = defaultDuration,
): AnimationTriggerMetadata {
  return trigger("labelAnimation", [
    state("open", style({
      // display: 'inline',
      opacity: 1
    })),
    state("close", style({
      // display: 'none',
      opacity: 0
    })),
    transition("close => open", animate(`${animationDuration} ease-in`)),
    transition("open => close", animate(`${animationDuration} ease-out`)),
  ]);
}
