import { Component, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AppInjector } from "@app/app.module";
import { SearchResult, SearchService } from "@core/services";
import { ButtonType, CustomDialogComponent } from "@modules/common/components";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { debounceTime, filter, map } from "rxjs/operators";

@Component({
  selector: "abi-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent extends CustomDialogComponent {
  @ViewChild("searchInput") searchInput: TemplateRef<any>;
  searching = false;
  showTitleBar = false;
  searchTerm: string = "";
  buttons: ButtonType[] = [];
  searchSubject: Subject<string> = new Subject<string>();

  constructor(public activeModal: NgbActiveModal, private searchService: SearchService, private router: Router) {
    super(activeModal);
    this.searchSubject
    .pipe(
      this.notDisposed(),
      map((term) => term.trim()),//no empty strings
      filter((term) => term.length > 1),// at least two characters
      debounceTime(300)
    )
    .subscribe((term) => {
      this.executeSearch(term);
    });

    const prevSearch = this.searchService.getPreviousSearch();
    if(prevSearch.term) {
      this.searchTerm = prevSearch.term;
      this.items = prevSearch.results;
    }
  }

  modelChangeTrigger(term: string) {
    this.searchTerm = term;
    this.searchSubject.next(term);
  }

  executeSearch(term: string) {
    this.searching = true;
    this.searchService
    .search(term)
    .then((result) => {
      // assign latest terms and results
      this.searching = false;
      this.items = result;
      // console.log('executeSearch', 'searchService', result);
    });
  }

  items: SearchResult[] = [];
  selectItem(item: any) {
    // console.log(item);
    this.searchService.savePreviousSearch();
    this.router.navigate([item.baseUrl, item.id]).then(() => {
      this.activeModal.close();
    });
  }

  static openSearch() {
    // console.log("open search");
    const modal = AppInjector.get(NgbModal);
    const modalRef = modal.open(SearchComponent, {
      size: "lg",
      centered: true,
      windowClass: "search-dialog",
    });
    modalRef.closed.subscribe(() => {
      // console.log("closed");
    });
    modalRef.dismissed.subscribe(() => {
      // console.log("dismissed");
    });
  }

  resultItemFormatter(item: SearchResult) {
    return `${item.id} ~ ${item.name}`;
  }
}
